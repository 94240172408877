export enum AlertActionTypes {
  SHOW_ALERT = 'SHOW_ALERT',
  HIDE_ALERT = 'HIDE_ALERT',
}

export type AlertMessageType = string;

export type AlertState = {
  message: AlertMessageType;
}

type ShowAlert = {
  type: AlertActionTypes.SHOW_ALERT;
  payload: string;
}

type HideAlert = {
  type: AlertActionTypes.HIDE_ALERT;
}

export type AlertAction = ShowAlert | HideAlert;