import { ShellFSState, ShellFSAction, ShellFSActionTypes } from '../../../types/charts/strandShellThickness'; 

const initState: ShellFSState = {
  straighteningArea: 19,
  data: null,
};

export const strandShellThicknessReducer = (state = initState, action: ShellFSAction): ShellFSState => {
  switch (action.type) {
  case ShellFSActionTypes.SET_SHELL_FC_DATA:
    return {
      ...state,
      data: action.payload
    };
  default:
    return state;
  }
  
   
};