import { AirPressureAction, AirPressureActionTypes, TypeDataAirPressure } from '../../../types/charts/airPressure';

export const setAirPressureData = (data: TypeDataAirPressure): AirPressureAction => ({
  type: AirPressureActionTypes.SET_AIR_PRESSURE_DATA,
  payload: data, 
});

export const fetchAirPressureRequest = (): AirPressureAction => ({
  type: AirPressureActionTypes.FETCH_AIR_PRESSURE_REQUEST,
});
  
