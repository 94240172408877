export const defaultOptions = {
  chart: {
    height: 200,
    ignoreHiddenSeries: false,
  },
  credits: {
    enabled: false,
  },
  title: {  
    text: 'Roll Gap',
    style: {
      fontSize: '21px',
      fontWeight: '700'
    }
  },
  xAxis: {
    visible: false,
    title: {
      text: '',
    },
    min: 0,
    max: 45,
    tickInterval: 2,
  },
  yAxis: [
    {
      title: {
        text: 'Roll Gap /mm',
        style: {
          fontSize: '14px',
          fontWeight: '700',
          color: 'green'
        }
      },
      min: 0,
      max: 500,
      tickInterval: 50,
    },
    {
      title: {
        text: 'Roll Gap /mm',
        style: {
          fontSize: '14px',
          fontWeight: '700',
          color: 'green'
        }
      },
      linkedTo: 0,
      gridLineWidth: 0,
      opposite: true,
      min: 0,
      max: 500,
      tickInterval: 50,
    }
  ],
  plotOptions: {
    series: {
      pointWidth: 1,
    }
  },
  legend: {
    enabled: false,
  },
};