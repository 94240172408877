import { RollGapAction, RollGapActionsTypes, TypeRollGapData } from '../../../types/charts/rollGap';

export const setRollGapData = (data: TypeRollGapData): RollGapAction => ({
  type: RollGapActionsTypes.SET_ROLL_GAP_DATA,
  payload: data, 
});
 
export const fetchRollGapRequest = (): RollGapAction => ({
  type: RollGapActionsTypes.FETCH_ROLL_GAP_REQUEST,
});
  
