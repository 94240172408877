

export const defaultOptions = {
  chart: {
    type: 'spline', 
    spacingTop: 20,
    spacingBottom: 40,
    // height: 520,
    ignoreHiddenSeries: false,
  }, 
  plotOptions: {
    series: {
      marker: {
        enabled: false
      }
    }
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: true,
  },
  xAxis: [
    {
      crosshair: true,
      tickInterval: 2, 
      min: 0,
      max: 46,
      title: {
        text: 'Distance from Mold Level/ m',
        style: {
          fontWeight: 'bold',
          fontSize: '18px' 
        }
      },
    },
    {
      crosshair: true,
      tickInterval: 2, 
      min: 0,
      max: 46,
      opposite: true,

    },
  ],
  yAxis: [
    { // left y axis
      visible: true,
      min: 0,
      max: 120,
      title: {
        text: 'Shell Thickness/ mm',
        style: {
          color: 'red',
          fontSize: '16px',
          fontWeight: 'bold'
        }
      },
      tickInterval: 10,
      showFirstLabel: false
    },
    { // right y axis
      linkedTo: 0,
      gridLineWidth: 0, 
      opposite: true,
      visible: true, 
      zIndex: 98,
      title: {
        text: 'Shell Thickness/ mm',
        style: {
          color: '#000',
          fontSize: '16px',
          fontWeight: 'bold',
        }
      },
      showFirstLabel: false
    }],
};