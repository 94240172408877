
export type TypeShellFSData = {
  Length: number[] | null,
  ThicknessTopFl0_00: number[] | null,
  ThicknessTopFl0_20: number[] | null,
  ThicknessTopFl0_40: number[] | null,
  ThicknessTopFl0_60: number[] | null,
  ThicknessTopFl0_80: number[] | null,
  ThicknessTopFl1_00: number[] | null,
}



export interface ShellFSState{ 
  data: TypeShellFSData | null,
  straighteningArea: number | null,
}
    
 
export enum ShellFSActionTypes {
    SET_SHELL_FC_DATA = 'SET_SHELL_FC_DATA',
    FETCH_SHELL_FC_REQUEST = 'FETCH_SHELL_FC_REQUEST',
  }
   

  type FetchShellFSRequest = {
    type: ShellFSActionTypes.FETCH_SHELL_FC_REQUEST
  };
  
  type SetShellFCRequest = {
      type: ShellFSActionTypes.SET_SHELL_FC_DATA,
      payload: TypeShellFSData
  }

  
export type ShellFSAction = 
  FetchShellFSRequest | 
  SetShellFCRequest;
