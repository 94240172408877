import { ShellAction, ShellActionTypes, ShellState } from '../../../types/charts/shell';

const initState: ShellState = {
  data: null,
};

export const shellReducer = (state = initState, action: ShellAction): ShellState  => {
  switch (action.type) {
  case ShellActionTypes.SET_SHELL_DATA:
    return { ...state, data: action.payload }; 
  default:
    return state;
  }
};