export type ParameterPanelState = {
  tempNo: number | null,
  spPlanNo: number | null,
  castThickness: number | null,
  currentMaterial: string | null,
  trProcess: number | null,
  trProcess9: number | null,
  trProcess10: number | null,
  trProcess11: number | null,
  modelState: string | null,
}

export enum ParameterPanelActionTypes {
  SET_CASTTHICKNESS = 'SET_CASTTHICKNESS',
  SET_TPROCESS = 'SET_TPROCESS'
}

type SetCastThickness = {
  type: ParameterPanelActionTypes.SET_CASTTHICKNESS,
  payload: number,
}

type SetTProcess = {
  type: ParameterPanelActionTypes.SET_TPROCESS,
  payload: number,
}

export type ParameterPanelAction = SetCastThickness | SetTProcess;