import { CommonLinesAction, TypeDataCommonLines, CommonLinesActionTypes } from '../../../types/charts/commonLines';


export const setCommonLinesData = (data: TypeDataCommonLines): CommonLinesAction => ({
  type: CommonLinesActionTypes.SET_COMMON_LINES_DATA,
  payload: data, 
});

export const fetchCommonLinesRequest = (): CommonLinesAction => ({
  type: CommonLinesActionTypes.FETCH_COMMON_LINES_REQUEST,
});
  
