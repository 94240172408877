import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { options as chartOptions } from './options';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getSelectedWaterflowItem } from '../../../store/selectors/charts/waterflow/waterflowSelector';
import { omit, transform } from 'lodash';
import { IWaterflow } from '../../../interfaces/charts/IWaterflow';
import { useSelector } from 'react-redux';
import { getHeaderTopMenuBtnStatus } from '../../../store/selectors/header/headerSelector';
import { getStatusRightPanel } from '../../../store/selectors/rightPanel/rightPanel';
import { getStatusLeftPanel } from '../../../store/selectors/leftPanel/leftPanelSelector';


const configurateSeries = (currentItem: IWaterflow | null) => {
  const minSeries = [];
  const maxSeries = [];
  let waterflowSeries: number[][] = [];
  let waterflowSpeed = 0;
  const step = 0.2;

  if (currentItem) {
    const newObj = omit(currentItem, ['ctrl', 'id', 'loopName', 'max', 'min']);

    waterflowSeries = transform(newObj, (acc, current) => {
      waterflowSpeed += step;
      acc.push([parseFloat(waterflowSpeed.toFixed(2)), current]);
      return acc;
    }, [] as number[][]);

    for (let i = 0, speed = 0; i < 10; i++) {
      speed += step;
      minSeries.push([parseFloat(speed.toFixed(1)), currentItem?.min]);
      maxSeries.push([parseFloat(speed.toFixed(1)), currentItem?.max]);
    }
  }

  return [
    {
      name: 'Min',
      type: 'line',
      color: 'blue',
      dashStyle: 'shortdash',
      data: [...minSeries],
    },
    {
      name: 'Max',
      type: 'line',
      color: 'red',
      dashStyle: 'shortdash',
      data: [...maxSeries],
    },
    {
      name: 'Waterflow',
      type: 'line',
      data: [...waterflowSeries],  
    }
  ];
};

export const WaterflowChart = (): JSX.Element => {
  const selectedWaterflowItem = useAppSelector(getSelectedWaterflowItem);
  const series = useMemo(() => configurateSeries(selectedWaterflowItem), [selectedWaterflowItem]);
  const options = { ...chartOptions, series };

  const topMenuIsHide = useSelector(getHeaderTopMenuBtnStatus);
  const rightMenuIsHide = useSelector(getStatusRightPanel);
  const leftMenuIsHide = useSelector(getStatusLeftPanel);

  const styles = topMenuIsHide ? { height: 'calc( 100vh - 100px )', minHeight: '650px' } : { height: 'calc( 100vh - 260px )', minHeight: '650px' };
  const chartKey = `${topMenuIsHide}${rightMenuIsHide}${leftMenuIsHide}`;

  return ( 
    <HighchartsReact
      key={chartKey}
      containerProps={{ style: styles }} 
      highcharts={Highcharts}  
      options={options}
    /> 
  );
};
