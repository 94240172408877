
export const defaultOptions = {
  chart: { 
    spacingTop: 20,
    height: 150,
    ignoreHiddenSeries: false,
  },
  title: {
    text: 'Solidification Arrow Chart',
    align: 'center', 
    margin: 0,
    style: {
      fontWeight: 'bold'
    }
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false 
      }
    },
    spline: {
      linecap: 'square',
    },
    line: {
      linecap: 'square',
    }
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    crosshair: true,
    tickInterval: 1,
    min: 0,
    max: 46,
    visible: false,
  },
  yAxis: [
    { // left y axis
      visible: true,
      min: 900,
      max: 1200,
      title: {
        text: 'Temper',
        style: {
          color: 'red',
          fontSize: '16px',
          fontWeight: 'bold'
        }
      },
      tickInterval: 100,
      showFirstLabel: false
    },
    { // right y axis
      linkedTo: 0,
      gridLineWidth: 0,
      opposite: true,
      visible: true,
      title: {
        text: 'Temper',
        style: {
          color: 'red',
          fontSize: '16px',
          fontWeight: 'bold',
        }
      },
      showFirstLabel: false
    }],
  tooltip: {
    headerFormat: '',
    pointFormat: '{point.x}',
  },
};