import { put, takeEvery, StrictEffect, call } from 'redux-saga/effects';
import { ShellFSActionTypes } from '../../store/types/charts/strandShellThickness';
import { setShellFSData } from '../../store/actions/charts/shellFS/shellFSActions';
import { RollGapActionsTypes } from '../../store/types/charts/rollGap';
import { setRollGapData } from '../../store/actions/charts/rollGap/rollGapActions';
import { softReduction } from '../../api';
import { AxiosResponse } from 'axios';
 
// workers 

function* fetchShellFSChartData() { 
  try {
    const data: AxiosResponse = yield call(softReduction.getShellFS);
    yield put(setShellFSData(data.data)); 
  } catch (err) {
    console.log(err);
  }
} 


function* fetchRollGapChartData() { 
  try {
    const data: AxiosResponse = yield call(softReduction.getRollGap);
    yield put(setRollGapData(data.data)); 
  } catch (err) {
    console.log(err);
  }
} 

// watchers

export function* softReductionWatcher(): Generator<StrictEffect> {
  yield takeEvery(ShellFSActionTypes.FETCH_SHELL_FC_REQUEST, fetchShellFSChartData);
  yield takeEvery(RollGapActionsTypes.FETCH_ROLL_GAP_REQUEST, fetchRollGapChartData);
} 