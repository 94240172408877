import { LeftPanelAction, LeftPanelActionTypes } from '../../types/leftPanel';

export const setIsOpen = (value: boolean): LeftPanelAction => ({
  type: LeftPanelActionTypes.SET_IS_OPEN,
  payload: value
});
export const setCastLength = (value: number): LeftPanelAction => ({
  type: LeftPanelActionTypes.SET_CAST_LENGTH,
  payload: value
});
export const setCastingTemp = (value: number[]): LeftPanelAction => ({
  type: LeftPanelActionTypes.SET_CASTING_TEMP,
  payload: value
});
export const setSpeed = (value: number): LeftPanelAction => ({
  type: LeftPanelActionTypes.SET_SPEED,
  payload: value
});
export const setSpraywater = (value: number): LeftPanelAction => ({
  type: LeftPanelActionTypes.SET_SPRAYWATER,
  payload: value
});
export const setMoldWaterTemp = (value: number[]): LeftPanelAction => ({
  type: LeftPanelActionTypes.SET_MOLD_WATER_TEMP,
  payload: value
});
export const setSolidificationLength = (value: number): LeftPanelAction => ({
  type: LeftPanelActionTypes.SET_SOLIDIFICATION_LENGTH,
  payload: value,
});