import { SolidificationArrowChartState } from '../../../types/charts/solidificationArrowChart';

const initState: SolidificationArrowChartState = {
  line1: [4, 16],
  line2: [16, 21],
  line3: [20.78, 26],
  line4: [26, 32],
  line5: [24.2, 25], 
  arrow: 26  

}; 


export const solidificationArrowChartReducer = (state = initState): SolidificationArrowChartState => {
  
  return state;
   
};