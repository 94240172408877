
export const options = {
  chart: {
    type: 'spline', 
    spacingTop: 20,
    spacingBottom: 40,
    // height: 520,
    ignoreHiddenSeries: false,
  }, 
  plotOptions: {
    series: {
      marker: {
        enabled: false
      }
    },
    spline: {
      linecap: 'square',
    },
    line: {
      linecap: 'square',
    }
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: true,
  },
  xAxis: [{
    crosshair: true,
    tickInterval: 1, 
    min: 0,
    max: 46,
    title: {
      text: 'Distance from Mold Level/ m',
      style: {
        fontWeight: 'bold',
        fontSize: '18px' 
      }
    },
  }],
  yAxis: [
    { // left y axis
      visible: true,
      min: 600,
      max: 1600,
      title: {
        text: 'Temperature/ °C',
        style: {
          color: 'red',
          fontSize: '16px',
          fontWeight: 'bold'
        }
      },
      tickInterval: 100,
      showFirstLabel: false
    },
    { // right y axis
      linkedTo: 0,
      gridLineWidth: 0, 
      opposite: true,
      visible: true, 
      zIndex: 98,
      title: {
        text: 'Temperature/ °C',
        style: {
          color: 'red',
          fontSize: '16px',
          fontWeight: 'bold',
        }
      },
      showFirstLabel: false
    }] 
};