
export const defaultOptions = {
  chart: { 
    type: 'spline',
    spacingRight: 60,
    spacingLeft: 40,
    height: 70,
    ignoreHiddenSeries: false,
  },
  
  title: {
    text: 'Water Volume Flow Specificin Cooling Zones',
    align: 'center', 
    margin: 0,
    style: {
      fontWeight: 'bold'
    }
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false 
      }
    },
    spline: {
      linecap: 'square',
    },
    line: {
      linecap: 'square',
    }
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: false,
  },
  xAxis: [{
    crosshair: true,
    tickInterval: 0.1,
    min: 0,
    max: 1.2,
    visible: false,
    plotLines: [{
      color: '#FF0000', // Red
      width: 2,
      value: 0// Position, you'll have to translate this to the values on your x axis
    }]
  }],
  yAxis: [
    { // left y axis
      visible: true,
      min: 0,
      max: 20,
      title: {
        text: 'Temper',
        style: {
          color: 'red',
          fontSize: '16px',
          fontWeight: 'bold'
        }
      },
      tickInterval: 100,
      showFirstLabel: false
    }],
  tooltip: {
    headerFormat: '',
    pointFormat: '{point.x}',
  },
};