import { AirPressureState, AirPressureActionTypes, AirPressureAction } from '../../../types/charts/airPressure';

const initState: AirPressureState = {
  index: [
    1.0,
    2.0,
    3.0,
    4.0,
    5.0,
    6.0,
    7.0,
    8.0,
    
  ],
  data: null
}; 

export const airPressureReducer = (state: AirPressureState = initState, action: AirPressureAction): AirPressureState => {
  switch (action.type) {
  case AirPressureActionTypes.SET_AIR_PRESSURE_DATA:
    return {
      ...state,
      data: action.payload
    };
  
  default:
    return state;
  }
  
}; 