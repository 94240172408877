import { AuthAction, AuthActionTypes, AuthState } from '../../../types/app/auth';
import { getCookie } from '../../../../utils/cookie/cookie';
import { COOKIE_TOKEN } from '../../../../constants/cookie';

const initialState: AuthState = {
  isLogged: !!getCookie(COOKIE_TOKEN),
};

export const authReducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
  case AuthActionTypes.LOG_IN:
    return { ...state, isLogged: true };
  case AuthActionTypes.LOG_OUT:
    return { ...state, isLogged: false };
  default:
    return state;
  }
};