export const defaultOptions = {
  chart: {
    // height: 530,
    ignoreHiddenSeries: false,
  },
  title: {  
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: [
    {
      title: {
        text: '',
      },
      opposite: true,
      min: 0,
      max: 46,
      tickInterval: 2,
    },
    {
      title: {
        text: 'Distance from Mold Level /m',
        style: {
          fontSize: '14px',
          fontWeight: '700',
          color: '#000000'
        }
      },
      min: 0,
      max: 46,
      tickInterval: 2,
    }
  ],
  yAxis: [
    {
      title: {
        text: 'Shell Thickness /mm',
        style: {
          fontSize: '14px',
          fontWeight: '700',
          color: '#1100bb'
        }
      },
      min: 0,
      max: 125,
      tickInterval: 5,
      offset: 0,
    },
    {
      title: {
        text: 'Shell Thickness /mm',
        style: {
          fontSize: '14px',
          fontWeight: '700',
          color: '#000'
        }
      },
      linkedTo: 0,
      gridLineWidth: 0,
      opposite: true,
      min: 0,
      max: 125,
      tickInterval: 5,
    }
  ],
  plotOptions: {
    series: {
      pointWidth: 1,
    }
  },
  series: []
};