export type TypeTempDistribution = {
  HTS_0: number[] | null,
  HTS_1: number[] | null,
  HTS_2: number[] | null,
  HTS_3: number[] | null,
  HTS_4: number[] | null,
  HTS_5: number[] | null,
  HTS_6: number[] | null,
  HTS_7: number[] | null,
  TSolidusThicknessValues: number[] | null,
}

export interface DataTempDistributionState {
  data: TypeTempDistribution | null,
  lengthX: number[] | null,
  lengthXForSolid: number[] | null,
  scannerAct2: number,
  scannerAct2_v1: number,
  scannerAct2_v2: number,
  scannerAct2_v3: number,
  temperatureSetpoint: number,
  scannerMax: number
}

export enum TempDistributionActionsTypes {
  SET_TEMP_DISTRIBUTION_DATA = 'SET_TEMP_DISTRIBUTION_DATA',
  FETCH_TEMP_DISTRIBUTION_REQUEST = 'FETCH_TEMP_DISTRIBUTION_REQUEST'
}
 

type FetchTempDistributionRequest = {
  type: TempDistributionActionsTypes.FETCH_TEMP_DISTRIBUTION_REQUEST
};

type SetTempDistributionData = {
  type: TempDistributionActionsTypes.SET_TEMP_DISTRIBUTION_DATA,
  payload: TypeTempDistribution  
}
 
export type TempDistributionAction = 
FetchTempDistributionRequest |
SetTempDistributionData;
