export interface LeftPanelState {
  mode: string | null,
  heatID: string | number| null,
  castLength: number | null,
  steelGrade: string | null,
  castingTemperature: number[] | null,
  castDimensiton: number[] | null,
  solidificationLength: number | null,
  speed: number | null,
  spraywater: number | null,
  moldWaterVolume: number[] | null,
  moldWaterTemperature: number[] | null,
  level: number | null,
  isOpen: boolean,
} 

export enum LeftPanelActionTypes {
  SET_IS_OPEN = 'SET_IS_OPEN',
  SET_CAST_LENGTH = 'SET_CAST_LENGTH',
  SET_CASTING_TEMP = 'SET_CASTING_TEMP',
  SET_SPEED = 'SET_SPEED',
  SET_SPRAYWATER = 'SET_SPRAYWATER',
  SET_MOLD_WATER_TEMP = 'SET_MOLD_WATER_TEMP',
  SET_SOLIDIFICATION_LENGTH = 'SET_SOLIDIFICATION_LENGTH'
}

type SetIsOpen = {
  type: LeftPanelActionTypes.SET_IS_OPEN,
  payload: boolean
}

type SetCastLength = {
  type: LeftPanelActionTypes.SET_CAST_LENGTH,
  payload: number
}

type SetCastingTemp = {
  type: LeftPanelActionTypes.SET_CASTING_TEMP,
  payload: number[]
}

type SetSpeed = {
  type: LeftPanelActionTypes.SET_SPEED,
  payload: number
}

type SetSpraywater = {
  type: LeftPanelActionTypes.SET_SPRAYWATER,
  payload: number
}

type SetMoldWaterTemp = {
  type: LeftPanelActionTypes.SET_MOLD_WATER_TEMP,
  payload: number[]
}

type SetSolidificationLength = {
  type: LeftPanelActionTypes.SET_SOLIDIFICATION_LENGTH,
  payload: number,
}

export type LeftPanelAction = 
  SetCastLength |
  SetCastingTemp |
  SetMoldWaterTemp |
  SetSpeed |
  SetMoldWaterTemp | 
  SetSpraywater |
  SetIsOpen |
  SetSolidificationLength;