import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import { AuthActionTypes, TryLogIn } from '../../store/types/app/auth';
import axios, { AxiosResponse } from 'axios';
import { auth } from '../../api';
import { setCookie } from '../../utils/cookie/cookie';
import { COOKIE_TOKEN } from '../../constants/cookie';
import { hideAlert, showAlert } from '../../store/actions/app/alert/alertActions';
import { setLogIn } from '../../store/actions/app/auth/authActions';

function* setAuthInWorker(action: TryLogIn) {
  const { payload: loginData } = action;

  try {
    yield put(hideAlert());
    const response: AxiosResponse = yield call(auth.logIn, loginData);
    yield setCookie(COOKIE_TOKEN, response.data.accessToken);
    yield put(setLogIn());
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(showAlert('This User is not registered or Password is incorrect'));
    } else {
      console.log(e);
    }
  }
}

export function* setAuthInWatcher(): Generator<StrictEffect> {
  yield takeEvery(AuthActionTypes.LOG_TRY, setAuthInWorker);
}