import React, { useEffect, useState } from 'react';
import Alert, { Color } from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import makeStyles from '@material-ui/styles/makeStyles';
import { Snackbar } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getAlertMessage } from '../../store/selectors/app/alert/alertSelector';

interface AlertProps {
  title?: string;
  severity?: Color; //error, warning, info, success
}

const useStyles = makeStyles({
  alertStyles: {
    width: '100%',
    position: 'absolute',
  },
});

const AlertMessage: React.FC<AlertProps> = ({ title = 'Error', severity = 'error' }) => {
  const alertMessage = useSelector(getAlertMessage);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!alertMessage);
  }, [alertMessage]);

  const handleClose = () => setIsOpen(false);

  if (!alertMessage) return null;

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpen} autoHideDuration={3000}
      onClose={handleClose}
      className={classes.alertStyles}
    >
      <Alert severity={severity} onClose={handleClose}>
        <AlertTitle>{title}</AlertTitle>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;