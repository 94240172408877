
export type TypeDataCommonLines = {
    coolzonePasslineCoor: number[] | null,
    segmentPasslineCoor: number[] | null,
    lastRoll: number[] | null,
    solidificationLength: number[],
    solidificationWarning: number[] | null,
    solidificationAlarm: number[] | null,
    endOfStrendGuide: number[] | null,
  }
  
export interface CommonLinesState{ 
      data: TypeDataCommonLines | null
  }
      
   
export enum CommonLinesActionTypes {
      SET_COMMON_LINES_DATA = 'SET_COMMON_LINES_DATA',
      FETCH_COMMON_LINES_REQUEST = 'FETCH_COMMON_LINES_REQUEST'
    }
     
  
  type FetchCommonLinesRequest = {
    type: CommonLinesActionTypes.FETCH_COMMON_LINES_REQUEST
  };
  
  type SetCommonLinesData = {
      type: CommonLinesActionTypes.SET_COMMON_LINES_DATA,
      payload: TypeDataCommonLines 
  }
    
export type CommonLinesAction = 
FetchCommonLinesRequest |
SetCommonLinesData;