import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { ILogin } from '../../interfaces/ILogin';
import { getAuthStatus } from '../../store/selectors/app/auth/authSelector';
import { useSelector } from 'react-redux';
import { tryLogIn } from '../../store/actions/app/auth/authActions';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import Alert from '../../components/Alert/Alert';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    width: '100vw',
  }
});

export default function Login (): JSX.Element {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const isLogged = useSelector(getAuthStatus);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (isLogged) history.replace('/starter');
  }, [isLogged, history]);

  const handleSubmit = (loginData: ILogin): void => {
    dispatch(tryLogIn(loginData));
  };

  return (
    <>
      <Alert />
      <Box className={classes.wrapper}>
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email(`${t('SIGN_IN_EMAIL_INVALID')}`).max(255).required(`${t('SIGN_IN_EMAIL_REQUIRED')}`),
              password: Yup.string().max(255).required(`${t('SIGN_IN_PASSWORD_REQUIRED')}`)
            })}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              dirty,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h3"
                  >
                    {t('SIGN_IN_TITLE')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    {t('SIGN_IN_SUBTITLE')}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('SIGN_IN_EMAIL')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t('SIGN_IN_PASSWORD')}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box py={2} >
                  <Button
                    color="primary"
                    disabled={!isValid || !dirty}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('SIGN_IN_TITLE')}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
}