
import { put, takeEvery, StrictEffect, call } from 'redux-saga/effects';
import { commonLines } from '../../api';
import { CommonLinesActionTypes } from '../../store/types/charts/commonLines';
import { setCommonLinesData } from '../../store/actions/charts/commonLines/commonLinesActions';
import { AxiosResponse } from 'axios';
  
// workers 

function* fetchCommonData() {
  try {
    const data: AxiosResponse = yield call(commonLines.getCommonLines);
    yield put(setCommonLinesData(data.data)); 
  } catch (err) {
    console.log(err);
  }
} 


// watchers

export function* commonWatcher(): Generator<StrictEffect> {
  yield takeEvery(CommonLinesActionTypes.FETCH_COMMON_LINES_REQUEST, fetchCommonData);
} 