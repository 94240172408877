import { TemperaturesChartAction, TemperaturesChartActionsTypes, DataTemperaturesChartState } from '../../../types/charts/temperaturesChart'; 
   
const initState: DataTemperaturesChartState = {
  data: null,
  staticData: null,
  soll: [
    1181.0,
    977.0,
    951.0,
    934.0,
    916.0,
    907.0
  ], 
  straighteningArea: 19,
  redBar1: [1, 4.5],
  redBar2: [5, 9],
  heatLine1: [1, 4],
  heatLine2: [5, 8.5], 
}; 


export const temperaturesChartReducer = (state = initState, action: TemperaturesChartAction): DataTemperaturesChartState => {

  switch (action.type) {
  case TemperaturesChartActionsTypes.SET_TEMPERATURES_CHART_DATA:
    return {
      ...state,
      data: action.payload
    };
  case TemperaturesChartActionsTypes.SET_TEMPERATURES_CHART_STATIC_LINES_DATA:
    return {
      ...state,
      staticData: action.payload
    };
  default:
    return state;
  }
  
};