import { ShellFSAction, ShellFSActionTypes, TypeShellFSData } from '../../../types/charts/strandShellThickness';

export const setShellFSData = (data: TypeShellFSData): ShellFSAction => ({
  type: ShellFSActionTypes.SET_SHELL_FC_DATA,
  payload: data, 
}); 
 
export const fetchShellFSRequest = (): ShellFSAction => ({
  type: ShellFSActionTypes.FETCH_SHELL_FC_REQUEST,
});


  
  
