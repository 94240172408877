import { AxiosPromise } from 'axios';
import { apiService } from '../index';


export const airLoopData = {
  getAirPressure(): AxiosPromise<string> {
    return apiService.get('/Main/AirLoopDataByFilter?filter=amount,pressure');
  },
  getWaterSpecific(): AxiosPromise<string> {
    return apiService.get('/Main/CtrlLoopDataByFilter?filter=CastingSpeedSetPoints,WaterSpecificFix,WaterSpecificLoose');
  }
}; 


 