


export const options = {
  chart: { 
    type: 'line',
    spacingTop: 20,
    spacingRight: 250,
    ignoreHiddenSeries: false,
    alignTicks: false,
    // height: 680, 
  },
  title: {
    text: 'Temperature Distribution Exit Caster (Narrow Side)',
    align: 'center', 
    margin: 0, 
    x: 30,
    style: {
      fontWeight: 'bold'
    }
  },
  credits: {
    enabled: false
  },
  legend: {  
    enabled: true,
    layout: 'vertical',
    backgroundColor: '#ECFFFE',
    x: 250,
    align: 'right',
    verticalAlign: 'middle',
    borderWidth: 1,
    borderColor: '#D3DCE6',
    floating: true,
  },
  xAxis: [{
    crosshair: true,
    tickInterval: 50,
    min: 0,
    max: 250,
    visible: true, 
    labels: { 
      enabled: true
    },
    title: {
      text: 'Thickness/ mm',
      style: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: '18px'
      }
    },
  }],
  yAxis: [
    { // left y axis
      visible: true,
      tickInterval: 100,
      min: 600,
      max: 1300,
      title: {
        text: 'Temperature/℃',
        style: {
          color: 'green',
          fontSize: '16px',
          fontWeight: 'bold'
        }
      },
      showFirstLabel: false
    },
    { // right y axis
      gridLineWidth: 0,
      opposite: true,
      visible: true,
      tickInterval: 1,
      min: 0,
      max: 42,
      title: {
        text: 'Distance from Mold Level/m',
        style: {
          color: 'red',
          fontSize: '16px',
          fontWeight: 'bold',
        }
      },
      showFirstLabel: false
    }],

  tooltip: {
    headerFormat: '',
    pointFormat: '{point.x}',
  },
};