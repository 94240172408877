import React, { useEffect, useState, Fragment } from 'react';
import LayoutHeader from './components/Header/Header';
import LayoutFooter from './components/Footer/Footer';
import TopLine from './components/Topline/Topline';
import LeftPanel from './components/LeftPanel/LeftPanel';
import RightPanel from './components/RightPanel/RightPanel';
import { Home, Board, UserManagement } from './scenes';
import { DefaultLayout } from './layout';
import Routes from './routes';
import ConfigParser from './utils/helper/configParser';
import { Modals } from './components/Modals/Modals';
import i18n from './i18n/i18n';
import moment from 'moment';
import { IRoute } from './interfaces/IRoute';
import axios from 'axios';
import './App.css';
import { Spinner } from './components/common/Spinner/Spinner';
import { makeStyles } from '@material-ui/core';
import { fetchCommonLinesRequest } from './store/actions/charts/commonLines/commonLinesActions';
import Login from './scenes/Login/Login';
import { Route, Switch, useHistory } from 'react-router-dom';
import PageNotFound from './scenes/404/404';
import { useAppDispatch } from './hooks/useAppDispatch';
import { getAuthStatus } from './store/selectors/app/auth/authSelector';
import { useSelector, useStore } from 'react-redux';
import { setupInterceptors } from './api';

const useStyles = makeStyles({
  spinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const defaultRoutes: Array<IRoute> = [
  { path: '/', title: 'Home', exact: true, render: <Home /> },
  { path: '/user-managment', title: 'User Managment', render: <UserManagement /> },
];

const App: React.FC = () => {
  const store = useStore();
  setupInterceptors(store);
  const [routes, setRoutes] = useState(defaultRoutes);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isLogged = useSelector(getAuthStatus);
  const history = useHistory();

  useEffect(() => {
    if (!isLogged) history.replace('/login');
  }, [isLogged, history]);

  moment.locale(i18n.language);
  i18n.on('languageChanged', (lang) => moment.locale(lang));

  useEffect(() => {

    const fetchData = async () => {
      const responses = await Promise.all([
        axios.get('/locales/en/translation.json'),
        axios.get('/locales/de/translation.json'),
        axios.get('/configRoutes.json'),
      ]);

      i18n.addResourceBundle('en', 'translation', responses[0].data);
      i18n.addResourceBundle('de', 'translation', responses[1].data);

      const config = ConfigParser.getInstance();
      config.addRoutesConfig(responses[2].data);
      const boardRoutes = config.generateRoutes(<Board />);

      setRoutes(state => [...state, ...boardRoutes]);
      setLoading(false);
    };

    dispatch(fetchCommonLinesRequest());

    fetchData();

  }, [dispatch]);

  if (loading) {
    return <div className={classes.spinnerContainer}><Spinner /></div>;
  }

  return (
    <>
      <Switch>
        <Route path='/404' component={PageNotFound} />
        <Route exact path='/login' component={Login} />
        <Route
          path='/' render={() => (
            <Fragment>
              <DefaultLayout>
                <LayoutHeader />
                <div className="layout-body">
                  <TopLine />
                  <div className="layout-body__inner">
                    <LeftPanel />
                    <div className="layout-body__base">
                      <Routes routes={routes} />
                    </div>
                    <RightPanel />
                  </div>
                </div>
                <Modals />
                <div className="layout-footer__under"></div>
              </DefaultLayout>
              <LayoutFooter />
            </Fragment>
          )}
        />
      </Switch>
    </>
  );
};

export default App;
