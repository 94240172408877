
export type TypeDataAirPressure = {
  Pressure: number[],
  Amount: number[]
}

export interface AirPressureState{ 
    index: number[] | null,
    data: TypeDataAirPressure | null
}
    
 
export enum AirPressureActionTypes {
    SET_INDEX = 'SET_INDEX',
    SET_AIR_PRESSURE_DATA = 'SET_AIR_PRESSURE_DATA',
    FETCH_AIR_PRESSURE_REQUEST = 'FETCH_AIR_PRESSURE_REQUEST'
  }
   
type SetIndexData = {
  type: AirPressureActionTypes.SET_INDEX,
  payload: number[]
};

type FetchAirPressureRequest = {
  type: AirPressureActionTypes.FETCH_AIR_PRESSURE_REQUEST
};

type SetAirPressureData = {
    type: AirPressureActionTypes.SET_AIR_PRESSURE_DATA,
    payload: TypeDataAirPressure 
}
  
export type AirPressureAction = 
  SetIndexData |
  SetAirPressureData |
  FetchAirPressureRequest;