import * as React from 'react';

export interface DefaultLayout {
  children: React.ReactNode
}
export function DefaultLayout({
  children,
}: DefaultLayout): JSX.Element {
  return (
    <div className="layout">
      {children}
    </div>
  );
}