import * as React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import IconButton from '@material-ui/core/IconButton';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { IDialogTranslation } from '../../../interfaces/IDialogTranslation';
import { transformObjForLanguage } from '../../../utils/languages/transformLanguage';
import { DefaultDataGrid } from '../../common/DefaultDataGrid/DefaultDataGrid';


const useStyles = makeStyles({  
  wrapper: {
    padding: '20px',
  },
  wrappTable: { 
    marginTop: '20px', 
  },
  topLineTable: {
    paddingTop: '20px' 
  },
  table: {
    height: 500,
    width: '100%'
  } 
  
}); 


export const DialogueTranslation: React.FC = (): JSX.Element => { 

  const [selectLanguage, setSelectLanguage] = React.useState('All'); 
  const [item, setItem] = React.useState<IDialogTranslation[]>([]);
  const classes = useStyles({});   
  const { t } = useTranslation();   

  const handleChangeLanguage = (event: React.ChangeEvent<{ value: unknown }>) => { 
    setSelectLanguage(event.target.value as string);
    event.target.value === 'All' ? setItem(transformObjForLanguage()) : setItem(transformObjForLanguage(event.target.value)); 
  };   
  
        
   
  if (item.length === 0) { 
  
    selectLanguage === 'All' ? setItem(transformObjForLanguage()) : setItem(transformObjForLanguage(selectLanguage)); 
  }
   
 
 
  const headers = [
    { field: 'locale', headerName: 'Locale', flex: 0.3, editable: true },
    { field: 'code', headerName: 'Translation Code', flex: 1, editable: true },
    { field: 'value', headerName: 'Value', flex: 1, editable: true },
  ];
 
  const toolsButton = [
    { name: 'edit', icon: <EditRoundedIcon />, isDisabled: true },
    { name: 'add', icon: <AddBoxRoundedIcon />, isDisabled: true },
    { name: 'save', icon: <SaveRoundedIcon />, isDisabled: true },
    { name: 'refresh', icon: <CachedRoundedIcon />, isDisabled: true },
    { name: 'copy', icon: <FileCopyRoundedIcon />, isDisabled: true },
    { name: 'delete', icon: <DeleteRoundedIcon />, isDisabled: true },
  ];

  return (  
    <Grid className={classes.wrapper} container>  
      <Grid
        container alignItems="center" item
        lg={2} 
      > 
        <div>{t('LANGUAGE')}</div>
      </Grid>
      <Grid item lg={2}> 
        <Select
          labelId="select-language" 
          id="select-language"
          value={selectLanguage}
          onChange={handleChangeLanguage}
        >  
          <MenuItem value={'All'}>{t('ALL')}</MenuItem>
          <MenuItem value={'en'}>{t('ENGLISH')}</MenuItem>
          <MenuItem value={'de'}>{t('GERMAN')}</MenuItem>  
        </Select>
      </Grid>
      <Grid container className={classes.topLineTable} >
        <Grid
          container 
          justify="flex-end"
          alignItems="center" 
          item   
          lg={6}
        > 
          <div> {t('TRANSLATION')}</div>
        </Grid> 
        <Grid 
          item
          container
          justify="flex-end"
          alignItems="center"
          lg={6}
        >
          {toolsButton.map((button, index) =>  
            <IconButton
              color="inherit" component="span"
              key={index} 
              disabled={button.isDisabled}
            >         
              {button.icon}       
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.wrappTable} item lg={12}>
        <div className={classes.table}>
          <DefaultDataGrid columns={headers} rows={item} /> 
        </div>
      </Grid>      
    </Grid>  
  );     
}; 

