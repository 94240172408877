import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

interface StyleProps {
  color?: string;
}

const useStyles = makeStyles({
  spinner: {
    color: (props: StyleProps) => props.color || '#000000',
  }
});

export const Spinner: React.FC<StyleProps> = ({ color }) => {
  const classes = useStyles({ color });

  return (
    <div className={classes.spinner}>
      <CircularProgress color="inherit" />
    </div>
  );
};
