import { createSelector } from 'reselect';
import { RootReducer } from '../../';
import { HeaderState } from '../../types/header';


export const getHeaderData = (state: RootReducer): HeaderState => state.header;

export const getHeaderTopMenuBtnStatus = createSelector( 
  getHeaderData,
  (data: HeaderState) => data.topMenuIsHide || false
);