 
export const defaultOptions = {
  chart: {
    type: 'column', 
    spacingTop: 20,
    spacingRight: 60,
    spacingLeft: 40,
    // height: 255,
    ignoreHiddenSeries: false,
  },  
  plotOptions: {
    column: {
      pointPadding: 0,
      groupPadding: 0,
      pointPlacement: 'on',
      pointStart: 0,
      pointWidth: 90,
      dataLabels: {
        enabled: true,
        color: '#FFFFFF', 
        inside: true,
        align: 'center',
        backgroundColor: '#dedede',
        borderRadius: 10, 
        verticalAlign: 'middle',
        format: '{point.y:.2f}', 
        style: {
          textOutline: 'none' 
        },
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 5,
        }, 
      }
    },
    series: {
      dataLabels: {
        align: 'center',
        enabled: true,
        backgroundColor: '#dedede',
        borderRadius: 10, 
        color: '#000', 
        verticalAlign: 'middle',
        format: '{point.y:.2f}', 
        style: {
          textOutline: 'none' 
        },
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 5,
        }, 
      }
    },
  },
  title: {
    text: 'Water volume',
    align: 'center', 
    margin: 0, 
    style: {
      fontWeight: 'bold',
    }
  },
  credits: {
    enabled: false
  },
  legend: {  
    enabled: false,
    x: -70,
    layout: 'vertical',
    backgroundColor: '#ECFFFE',
    align: 'right',
    verticalAlign: 'middle',
    borderWidth: 1,
    borderColor: '#D3DCE6',
    floating: true,
  },
  xAxis: [{
    visible: false,
    tickInterval: 0.1,
    min: 0,
    
  }],
  yAxis: [
    { // left y axis
      visible: true,
      min: 0,
      max: 440, 
      tickInterval: 20,
      
      title: {
        text: 'Air Pressure/ bar',
        style: {
          color: 'blue',
          fontSize: '11.5px',
          fontWeight: 'bold'
        }
      },
    }],

  responsive: {
    rules: [{
      condition: {
        maxWidth: 1200
      },
      chartOptions: {
        plotOptions: {
          column: {
            pointWidth: 70,
          },
        },
      }
    }]
  }
};