import React from 'react';
import { useTranslation } from 'react-i18next';

const Connection: React.FC = () => {

  const { t } = useTranslation();

  const DataConnection = {
    name: 'DESKTOP',
    parameters: [
      {
        name: t('MODEL'),
        status: 'status-init'
      },
      {
        name: t('MATERIAL'),
        status: 'status-pause'
      },
      {
        name: t('DB'),
        status: 'status-off'
      },
      {
        name: t('BACKEND'),
        status: 'status-on'
      }
    ]
  };

  return (
    <div className="connections">
      <ul className="connections__list">
        {DataConnection.parameters.map((item, i) =>
          <li className="connections__cell" key={i}>
            <span className={`connection-item ${item.status}`}>{item.name}</span>
          </li>
        )
        }
      </ul>
      <span className="connections__name">{DataConnection.name}</span>
    </div>
  );
};

export default Connection;
