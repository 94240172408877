import { DataRollGapState, RollGapAction, RollGapActionsTypes } from '../../../types/charts/rollGap';

const initState: DataRollGapState = {
  data: null,
};

export const rollgapReducer = (state = initState, action: RollGapAction): DataRollGapState  => {
  switch (action.type) {
  case RollGapActionsTypes.SET_ROLL_GAP_DATA:
    return {
      ...state,
      data: action.payload
    };
  default:
    return state;

  }
};