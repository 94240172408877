import { createSelector } from 'reselect';
import { RootReducer } from '../../../index';
import { SolidificationArrowChartState } from '../../../types/charts/solidificationArrowChart';


export const getSolidificationArrowChart = (state: RootReducer): SolidificationArrowChartState => state.charts.solidificationArrowChart;

export const getSolidificationArrowChartLine1 = createSelector( 
  getSolidificationArrowChart,
  (data: SolidificationArrowChartState) => data.line1 || [] 
);

export const getSolidificationArrowChartLine2 = createSelector( 
  getSolidificationArrowChart,
  (data: SolidificationArrowChartState) => data.line2 || [] 
);

export const getSolidificationArrowChartLine3 = createSelector( 
  getSolidificationArrowChart,
  (data: SolidificationArrowChartState) => data.line3 || [] 
);

export const getSolidificationArrowChartLine4 = createSelector( 
  getSolidificationArrowChart,
  (data: SolidificationArrowChartState) => data.line4 || [] 
);

export const getSolidificationArrowChartLine5 = createSelector( 
  getSolidificationArrowChart,
  (data: SolidificationArrowChartState) => data.line5 || [] 
);

export const getSolidificationArrowChartArrow = createSelector( 
  getSolidificationArrowChart,
  (data: SolidificationArrowChartState) => data.arrow || [] 
);

