import React from 'react';
import { IRoute } from '../../interfaces/IRoute';
import { IConfigRoutes } from '../../interfaces/config/IConfigRoutes';
import { IConfigComponentsItem } from '../../interfaces/config/IConfigComponentsItem';
import { IFooterLink } from '../../interfaces/IFooterLink';
import { IRightPanelItem } from '../../interfaces/config/IRightPanelItem';
import { IRightPanelConfigItem } from '../../interfaces/config/IRightPanelConfigItem';
import { IBottomPanelConfigItem } from '../../interfaces/config/IBottomPanelConfigItem';
import { head } from 'lodash';
import configComponents from '../../config/configComponents.json';
import { IBoardComponent } from '../../interfaces/IBoardComponent';
import { COMPONENTS_COLLECTION } from '../../config/components';

export default class ConfigParser { 
  private static instance: ConfigParser;

  private configRoutes: IConfigRoutes | undefined;
  private configComponents = configComponents as IConfigComponentsItem[];

  private constructor() {}

  public static getInstance(): ConfigParser {
    if (!ConfigParser.instance) {
      ConfigParser.instance = new ConfigParser();
    }
    return ConfigParser.instance;
  }

  public addRoutesConfig(config: IConfigRoutes): void {
    this.configRoutes = config;
  }

  private getConfigRightPanel(): IRightPanelConfigItem[] {
    return this.configRoutes ? this.configRoutes.rightPanel : [];
  }

  private getConfigBottomPanel(): IBottomPanelConfigItem[] {
    return this.configRoutes ? this.configRoutes.bottomPanel : [];
  }

  public getBottomPanelItemByPath(pathname: string): IBottomPanelConfigItem | null {
    return this.getConfigBottomPanel().find(item => pathname.includes(item.name)) || null;
  }

  public getRightPanelItemByPath(pathname: string): IRightPanelConfigItem | null {
    return this.getConfigRightPanel().find(item => pathname.includes(item.name)) || null;
  }

  public getRightPanelItemsByBottomId(id: number): IRightPanelConfigItem[] | [] {
    return this.getConfigRightPanel().filter(item => item.bottomPanelId === id);
  }

  public getConfigComponentItem(path: string): IConfigComponentsItem | null {
    return this.configComponents.find(item => item.path === path) || null;
  }

  public generateRoutes(renderContainer: React.ReactNode): IRoute[] {
    const routes: IRoute[] = [];

    if (this.getConfigBottomPanel().length) {
      this.getConfigBottomPanel().forEach(bottomItem => {
        const rightPanelItems = this.getRightPanelItemsByBottomId(bottomItem.id);

        if (bottomItem.path) {
          routes.push({
            path: `/${bottomItem?.name}`,
            title: bottomItem.label,
            render: renderContainer,
          });
        }

        if (rightPanelItems.length) {
          rightPanelItems.forEach(rightItem => {
            if (rightItem.buttons && rightItem.buttons.length > 0) {
              rightItem.buttons.forEach(button => {
                routes.push({
                  path: `/${bottomItem?.name}/${rightItem.name}${button.path}`,
                  title: button.label,
                  render: renderContainer,
                });
              });
            } else {
              routes.push({
                path: `/${bottomItem?.name}/${rightItem.name}`,
                title: rightItem.label,
                render: renderContainer,
              });
            }
          });
        }
      });
    }

    return routes;
  }

  public getBottomPanelLinks(): IFooterLink[] | [] {
    const bottomLinks: IFooterLink[] = [];

    this.getConfigBottomPanel().forEach(bottomItem => {
      const rightPanelItem = this.getConfigRightPanel().find(item => {
        return item.bottomPanelId === bottomItem.id;
      });
        
      if (bottomItem.path) {
        bottomLinks.push({
          id: bottomItem.id,
          name: bottomItem.name,
          label: bottomItem.label,
          url: `/${bottomItem.name}`
        });

        return;
      }

      if (rightPanelItem) {
        const buttonPath = rightPanelItem.buttons?.length ? head(rightPanelItem.buttons)?.path : '';

        bottomLinks.push({
          id: bottomItem.id,
          name: bottomItem.name,
          label: bottomItem.label,
          url: `/${bottomItem.name}/${rightPanelItem?.name}${buttonPath}`,
        });
      }
    });

    return bottomLinks;
  }

  public getRightPanelItems(pathname: string): IRightPanelItem[] {
    const currentBottomItem = this.getConfigBottomPanel()
      .find(item => pathname.includes(item.name));
    const rightPanelItems = this.getConfigRightPanel()
      .filter(item => item.bottomPanelId === currentBottomItem?.id);

    return rightPanelItems.map(item => {
      const firstButtonPath = item.buttons ? head(item.buttons)?.path : '';
      const componentItem = item?.panel ? this.getConfigComponentItem(item.panel) : null;

      const buttons = item.buttons ? item.buttons.map(button => ({
        url: `/${currentBottomItem?.name}/${item.name}${button.path}`,
        text: button.label,
      })) : [];

      const panel = componentItem ? componentItem.components.map(item => ({
        col: item.col,
        component: COMPONENTS_COLLECTION.get(item.name),
      })) : [];

      return {
        url: `/${currentBottomItem?.name}/${item.name}${firstButtonPath || ''}`,
        name: item.name,
        text: item.label,
        buttons,
        panel,
      };
    });
  }

  public getComponents(pathname: string): IBoardComponent[] | null {
    const bottomPanelItem = this.getBottomPanelItemByPath(pathname);
    const rightPanelItem = this.getRightPanelItemByPath(pathname);
    const buttonItem = rightPanelItem && !rightPanelItem.path ? 
      rightPanelItem.buttons?.find(item => pathname.includes(item.path)) : null;

    let componentItem = null;

    if (buttonItem) {
      componentItem = this.getConfigComponentItem(buttonItem.path);
    }

    if (rightPanelItem?.path) {
      componentItem = this.getConfigComponentItem(rightPanelItem.path);
    }

    if (bottomPanelItem?.path) {
      componentItem = this.getConfigComponentItem(bottomPanelItem?.path);
    }

    return componentItem ? componentItem.components.map(item => ({
      col: item.col,
      component: COMPONENTS_COLLECTION.get(item.name),
    })) : null;
  }

  public getHomepage(): string | null {
    return this.configRoutes && this.configRoutes.homepage ? this.configRoutes.homepage : null;
  }
}