interface IConstraintLines {
  name: string,
  type: string,
  color: string,
  maxWidth: number,
  zIndex: number,
  tooltip: {
    headerFormat: string,
    pointFormat: string,
  },
  dataLabels: {
    enabled: boolean,
  },
  data: [{
    x: number | undefined,
    y: number,
    dataLabels: {
      enabled: boolean,
      allowOverlap: boolean,
      color: string,
      align: string,
      formatter: () => string,
      rotation: number,
      x: number,
      y: number,
    },
  },
    {
      x: number | undefined,
      y: number,
  }],
}


export const generateConstraintLines = (x: number | undefined, minY: number,  maxY: number, name: string, color: string, align = 'center'): IConstraintLines => {
  const alignState: { [key: string]: number } = {
    'center': 0,
    'left': -10,
    'right': 10
  };

  return {
    name,
    type: 'spline',
    color,
    maxWidth: 1,
    zIndex: 2,
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.x}',
    },
    dataLabels: {
      enabled: false,
    },
    data: [
      {
        x,
        y: minY,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          color,
          align: 'left',
          formatter: function(): string {
            // disabled, because formatter calls in the library and TS doesn't see the "x" property.
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const that = this as any;
            return `${name} @ ${that.x.toFixed(2)} m `;
          },
          rotation: -90,
          x: alignState[align],
          y: -10,
        },
      },
      {
        x,
        y: maxY
      }
    ],
  };
};