import { createSelector } from 'reselect';
import { RootReducer } from '../../../index';
import { TypeDataCommonLines } from '../../../types/charts/commonLines';
import  head  from 'lodash/head';

export const getCommonLinesData = (state: RootReducer): TypeDataCommonLines | null => state.charts.commonLines.data; 

export const getCommonLines = createSelector(
  getCommonLinesData,
  (data) => data ? ({
    ...data,
    lastRoll: head(data?.lastRoll?.map(item => item / 1000)),
    solidificationLength: head(data?.solidificationLength?.map(item => item / 1000)),
    solidificationWarning: head(data?.solidificationWarning?.map(item => item / 1000)),
    solidificationAlarm: head(data?.solidificationAlarm?.map(item => item / 1000)),
    endOfStrendGuide: head(data?.endOfStrendGuide?.map(item => item / 1000)),
  }) : null
);