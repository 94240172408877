import { CommonLinesAction, CommonLinesState, CommonLinesActionTypes } from '../../../types/charts/commonLines';

export const initState: CommonLinesState = {
  data: null
};

export const commonLinesReducer = (state = initState, action: CommonLinesAction): CommonLinesState => {
  switch (action.type) {
  case CommonLinesActionTypes.SET_COMMON_LINES_DATA:
    return {
      ...state,
      data: action.payload
    };
  default:
    return state;
  }
};