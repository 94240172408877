import { IWaterflow } from '../../../../interfaces/charts/IWaterflow';

export enum WaterflowActionTypes {
  SELECT_WATERFLOW_ITEM = 'SELECT_WATERFLOW_ITEM',
}

export type WaterflowChartData = IWaterflow[] | [];
export type SelectedWaterflowItem = IWaterflow | null;

export type WaterflowSate = {
  selected: SelectedWaterflowItem;
  data: WaterflowChartData;
}

type SelectWaterflowChart = {
  type: WaterflowActionTypes.SELECT_WATERFLOW_ITEM,
  payload: SelectedWaterflowItem,
}

export type WaterflowAction = SelectWaterflowChart;