import { ParameterPanelAction, ParameterPanelActionTypes } from '../../types/parameterPanel';

export const setCastThicknessData = (value: number): ParameterPanelAction => ({
  type: ParameterPanelActionTypes.SET_CASTTHICKNESS,
  payload: value,
});

export const setTProcessData = (value: number): ParameterPanelAction => ({
  type: ParameterPanelActionTypes.SET_TPROCESS,
  payload: value,
});