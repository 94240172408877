import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux';
import { getHeaderTopMenuBtnStatus } from '../../store/selectors/header/headerSelector';
import { getStatusRightPanel } from '../../store/selectors/rightPanel/rightPanel';
import { getStatusLeftPanel } from '../../store/selectors/leftPanel/leftPanelSelector';
interface Props {
  cordinates?: number[]
}

  
export const Chart: React.FC<Props> = ({ cordinates }): JSX.Element=> {
  const topMenuIsHide = useSelector(getHeaderTopMenuBtnStatus);
  const rightMenuIsHide = useSelector(getStatusRightPanel);
  const leftMenuIsHide = useSelector(getStatusLeftPanel);
  
  const options = {
    title: {  
      text: 'My chart'    
    },  
    series: [{   
      type: 'spline',  
      data: []   
    }] 
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function configurationOptions(options: any, cordinates: number[] | undefined) {
    if (cordinates) {
      options.series.map((el: { type: string, data: number[] }) => el.data.push(...cordinates));
    }
  } 

  configurationOptions(options, cordinates); 

  const styles = topMenuIsHide ? { height: 'calc( 100vh - 50px )', minHeight: '500px' } : { height: 'calc( 100vh - 200px )', minHeight: '500px' };
  const chartKey = `${topMenuIsHide}${rightMenuIsHide}${leftMenuIsHide}`;

  return ( 
    <HighchartsReact  
      key={chartKey}
      containerProps={{ style: styles }} 
      highcharts={Highcharts}  
      options={options}
    /> 
  );
}; 



