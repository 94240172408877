import React from 'react';

const Clock: React.FC = () => {

  const [date, setDate] = React.useState(new Date());
 
  React.useEffect(() => {
    
    const timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setDate(new Date());
  }

  return (
    <div className="clock">
      {date.toLocaleTimeString()}
    </div>
  );
};

export default Clock;