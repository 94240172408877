import React from 'react';
import Button from '@material-ui/core/Button';
import { Link as NavLink } from 'react-router-dom';
import Connection from '../Connection/Connection';
import { ParameterPanel } from '../ParameterPanel/ParameterPanel';
import 'moment/locale/de';
import XPact from '../../assets/images/x-pact.svg';
import SolidControl from '../../assets/images/solid-control.png';
import CurrentDay from '../CurrentDay/CurrentDay';
import { useTranslation } from 'react-i18next';
import { UserTools } from '../UserTools/UserTools';
import classNames from 'classnames';
import { getHeaderTopMenuBtnStatus } from '../../store/selectors/header/headerSelector';
import { useSelector } from 'react-redux';
 
   
const LayoutHeader: React.FC = () => { 
  const { t } = useTranslation();
 
  const MainMenu = [
    { name: 'MAIN_MENU_DEVELOP', link: '/' }, 
    { name: 'MAIN_MENU_DETECT_ANALYZER', link: '/' },
    { name: 'MAIN_MENU_ASSIST', link: '/' },
    { name: 'MAIN_MENU_MATERIAL_CENTER', link: '/' } 
  ]; 
   
 
  const topMenuIsHide = useSelector(getHeaderTopMenuBtnStatus); 
 
 
 
  return (
    <div className={classNames('layout-header', { 'layout-header-active': topMenuIsHide })}> 
      <div className="layout-header__top">
        <div className="container">
          <div className="layout-header__inner">
            <ul className="nav-links">
              {MainMenu.map((item, i) => 
                <li className="nav-links__item" key={i}>
                  <Button
                    component={NavLink}
                    color="inherit"
                    to={item.link}
                    className="nav-links__link"
                    disabled
                  > {t(item.name)} 
                  </Button>
                </li>
              )
              }
            </ul> 
            <Connection />
            <UserTools />
          </div>
        </div> 
      </div>
      <div className="layout-header__base">
        <div className="container">
          <div className="layout-header__inner">
            <div className="layout-header__logo">
              <img src={XPact} alt="X-Pact" className="layout-header__xpact" />
              <img src={SolidControl} alt="Solid Control" className="layout-header__slogan" />
            </div>
            <div className="layout-header__parameter">
              <ParameterPanel />
            </div>
            <div className="layout-header__day">
              <CurrentDay />
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutHeader;
