import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import XPact from '../../assets/images/x-pact.svg';
import { Link, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toggleTopMenu } from '../../store/actions/header/headerAction';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { getHeaderTopMenuBtnStatus } from '../../store/selectors/header/headerSelector';
import { useSelector } from 'react-redux';
import { IconButton, makeStyles } from '@material-ui/core';
import ConfigParser from '../../utils/helper/configParser';

const useStyles = makeStyles({ 
  hideTopMenuBtn: {
    cursor: 'pointer',
    border: 0,
    background: 'none',

    '& .MuiButtonBase-root': {
      padding: 8
    }
  }
}); 

const TopLine: React.FC = () => { 
  const location = useLocation();  
  const config = ConfigParser.getInstance();
  const bottomItemLabel = config.getBottomPanelItemByPath(location.pathname)?.label || '';
  const classes = useStyles({});

  const dispatch = useDispatch();  


  const { t } = useTranslation();

  const topMenuIsHide = useSelector(getHeaderTopMenuBtnStatus); 


  // const dataStatus = [
  //   {
  //     name: 'MOLD',
  //     status: 'status-init'
  //   },
  //   {
  //     name: 'S-1',
  //     status: 'status-pause'
  //   },
  //   {
  //     name: 'STRAIT-AREA',
  //     status: 'status-off'
  //   },
  //   {
  //     name: 'HORIZONTAL',
  //     status: 'status-on'
  //   }
  // ];

  return (
    <div className="layout-body__topline">
      <div className="container">
        <div className="topline-inner">
          <div className="topline-cell">
            <img src={XPact} alt="X-Pact" className="topline-logo" />
            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />} className="breadcrumb-default">
              <Link color="inherit" to="/">
                {t('DIALOGUES')}
              </Link>
              <Link color="inherit" to="/">
                {t('FILE')}
              </Link>
              <Link color="inherit" to={location.pathname}>
                {t(bottomItemLabel)}
              </Link>
            </Breadcrumbs>
          </div>
          {/* <div className="topline-cell">
            <div className="list-status"> 
              {dataStatus.map((item, i: number) =>
                <div className={`list-status__item ${item.status}`} key={i}>{t(item.name)}</div>
              )
              } 
            </div> 
          </div> */}
          <div className="topline-cell">
            <button className={classes.hideTopMenuBtn} onClick={() => dispatch(toggleTopMenu())}>
              {!topMenuIsHide ? 
                <IconButton 
                  color="inherit" component="span"  
                >         
                  <VerticalAlignTopIcon />    
                </IconButton>   :
                <IconButton 
                  color="inherit" component="span"  
                >         
                  <VerticalAlignBottomIcon />    
                </IconButton>
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopLine;