import { put, takeEvery, StrictEffect, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { solidification } from '../../api/solidification/solidification';
import { ShellActionTypes } from '../../store/types/charts/shell';
import { setShellData } from '../../store/actions/charts/shell/shellAction';
import { TempDistributionActionsTypes } from '../../store/types/charts/tempDistribution';
import { setTempDistributionData } from '../../store/actions/charts/tempDistribution/tempDistribution';
import { TemperaturesChartActionsTypes } from '../../store/types/charts/temperaturesChart';
import { setTemperaturesChartData, setTemperaturesChartStaticLinesData } from '../../store/actions/charts/temperatures/temperaturesActions';
  
// workers
function* fetchShellData() {
  try {
    const res: AxiosResponse = yield call(solidification.getShell);
    yield put(setShellData(res.data));
  } catch (err) {
    console.log(err);
  }
} 


function* fetchTempDistributionData() { 
  try {
    const data: AxiosResponse = yield call(solidification.getTempDistribution);
    yield put(setTempDistributionData(data.data)); 
  } catch (err) {
    console.log(err);
  }
} 

function* fetchTemperaturesChartData() {
  try {
    const data: AxiosResponse = yield call(solidification.getTemperaturesChart);
    yield put(setTemperaturesChartData(data.data)); 
  } catch (err) {
    console.log(err);
  } 
}

function* fetchTemperaturesChartStaticLinesData() { 
  try {
    const data: AxiosResponse = yield call(solidification.getTemperaturesChartStaticLines);
    yield put(setTemperaturesChartStaticLinesData(data.data)); 
  } catch (err) {
    console.log(err);
  }
}

// watchers
export function* shellWatcher(): Generator<StrictEffect> {
  yield takeEvery(ShellActionTypes.FETCH_SHELL_DATA_REQUEST, fetchShellData);
  yield takeEvery(TempDistributionActionsTypes.FETCH_TEMP_DISTRIBUTION_REQUEST, fetchTempDistributionData);
  yield takeEvery(TemperaturesChartActionsTypes.FETCH_TEMPERATURES_CHART_REQUEST, fetchTemperaturesChartData);
  yield takeEvery(TemperaturesChartActionsTypes.FETCH_TEMPERATURES_CHART_STATIC_LINES_REQUEST, fetchTemperaturesChartStaticLinesData);
} 




