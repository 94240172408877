     
export const VALIDATION_MESSAGE = { 
  REQUIRED: 'REQUIRED', 
  STRING_MIN_TEXT: 'STRING_MIN_TEXT',
  INTEGER: 'INTEGER',
  INTEGER_POSITIVE: 'INTEGER_POSITIVE',
  INTEGER_TYPE_ERROR: 'INTEGER_TYPE_ERROR',
};
 
  
 
 
   