import { DataWaterSpecificState, WaterSpecificAction, WaterSpecificActionsTypes } from '../../../types/charts/waterSpecific';

const initState: DataWaterSpecificState = {
  data: null,
  castingSpeedSetpoints: [
    0.1,
    0.2,
    0.3,
    0.4,
    0.5,
    0.6
  ],
  coolZones: [
    {
      label: 'Ring',
      value: 0.1
    },
    {
      label: 'Narrowside',
      value: 0.2
    },
    {
      label: 'S1',
      value: 0.3
    },
    {
      label: 'S2-3',
      value: 0.4
    },
    {
      label: 'S4-6',
      value: 0.5
    },
    {
      label: 'S7-8',
      value: 0.6
    },
  ],
}; 

export const WaterSpecificReducer = (state: DataWaterSpecificState = initState, action: WaterSpecificAction): DataWaterSpecificState => {
  switch (action.type) {
  case WaterSpecificActionsTypes.SET_WATER_SPECIFIC_DATA:
    return {
      ...state,
      data: action.payload
    }; 
  default:
    return state;

  }
}; 