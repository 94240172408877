import { put, takeEvery, StrictEffect, call } from 'redux-saga/effects';
import { AirPressureActionTypes } from '../../store/types/charts/airPressure';
import { setAirPressureData } from '../../store/actions/charts/airPressure/airPressureActions';
import { airLoopData } from '../../api';
import { WaterSpecificActionsTypes } from '../../store/types/charts/waterSpecific';
import { setWaterSpecificData } from '../../store/actions/charts/waterSpecific/waterSpecificActions';
import { AxiosResponse } from 'axios';
  
// workers

function* fetchAirLoopData() {
  try {
    const data: AxiosResponse = yield call(airLoopData.getAirPressure);
    yield put(setAirPressureData(data.data)); 
  } catch (err) {
    console.log(err);
  }
} 

function* fetchWaterSpecificData() {
  try {
    const data: AxiosResponse = yield call(airLoopData.getWaterSpecific);
    yield put(setWaterSpecificData(data.data)); 
  } catch (err) { 
    console.log(err);
  }
}

// watchers

export function* airLoopWatcher(): Generator<StrictEffect> {
  yield takeEvery(AirPressureActionTypes.FETCH_AIR_PRESSURE_REQUEST, fetchAirLoopData);
  yield takeEvery(WaterSpecificActionsTypes.FETCH_WATER_SPECIFIC_REQUEST, fetchWaterSpecificData);
} 