import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga'; 
import { rootReducer } from './reducers';
import { rootWatcher } from '../saga';
import { TypeWaterSpecificData } from './types/charts/waterSpecific';
import { SelectedWaterflowItem } from './types/charts/waterflow';
import { TypeTemperaturesChart, TypeTemperaturesChartStaticLines } from './types/charts/temperaturesChart';
import { TypeTempDistribution } from './types/charts/tempDistribution';
import { TypeShellFSData } from './types/charts/strandShellThickness';
import { TypeShellData } from './types/charts/shell';
import { TypeRollGapData } from './types/charts/rollGap';
import { TypeDataCommonLines } from './types/charts/commonLines';
import { TypeDataAirPressure } from './types/charts/airPressure';
import { ILogin } from '../interfaces/ILogin';
import { AnyAction } from 'typescript-fsa';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(sagaMiddleware)
));   

sagaMiddleware.run(rootWatcher);

export type RootReducer = ReturnType<typeof rootReducer>;
export type Store = typeof store;
export type AppDispatch = typeof store.dispatch;

export type ActionDataType = TypeWaterSpecificData | SelectedWaterflowItem | TypeTemperaturesChart | TypeTemperaturesChartStaticLines | TypeShellFSData | TypeShellData | TypeRollGapData | TypeDataCommonLines | TypeDataAirPressure | ILogin | TypeTempDistribution | void | null | number | string | boolean | number[];

export interface IActionWithOptionalPayload<T> extends AnyAction {
  payload?: T,
}