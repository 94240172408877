export type RightPanelState = {
  isOpen: boolean;
}

export enum RightPanelActionTypes {
  OPEN_RIGHT_PANEL = 'OPEN_RIGHT_PANEL',
  CLOSE_RIGHT_PANEL = 'CLOSE_RIGHT_PANEL',
}

type OpenRightPanel = {
  type: RightPanelActionTypes.OPEN_RIGHT_PANEL,
}

type CloseRightPanel = {
  type: RightPanelActionTypes.CLOSE_RIGHT_PANEL,
}

export type RightPanelAction = OpenRightPanel | CloseRightPanel;