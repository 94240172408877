import WaterTable from '../components/WaterTable/WaterTable';
import { Chart } from '../components/Chart/Chart';
import { WaterflowChart } from '../components/charts/WaterflowChart/WaterflowChart';
import { TemperaturesChart } from '../components/charts/TemperaturesChart/TemperaturesChart';
import { TempDistribution } from '../components/charts/TempDistribution/TempDistribution';
import { RollGapChart } from '../components/charts/RollGapChart/RollGapChart';
import { StrandShellThickness } from '../components/charts/StrandShellThickness/StrandShellThickness';
import { AirPressure } from '../components/charts/AirPressure/AirPressure';
import { WaterSpecific } from '../components/charts/WaterSpecific/WaterSpecific';
import { ShellChart } from '../components/charts/ShellChart/ShellChart';
import { ScaleCompositionChart } from '../components/charts/ScaleComposition/ScaleCompositionChart';

// const componentNames = ['WaterTable', 'Chart'];  
// const components = componentNames.reduce((acc: {[key: string]: React.ComponentType<any>}, current) => {
//   acc[current] = React.lazy(() => import(`../components/${current}/${current}`));

//   return acc;  
// }, {});

const configComponents = { 
  WaterTable,
  Chart,
  WaterflowChart,
  TemperaturesChart,
  RollGapChart,
  TempDistribution,
  StrandShellThickness,
  ShellChart,
  AirPressure, 
  WaterSpecific,
  ScaleCompositionChart,
};

export const COMPONENTS_COLLECTION = new Map(Object.entries(configComponents));
