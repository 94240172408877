import { RootReducer } from '../../../index';
import { DataRollGapState } from '../../../types/charts/rollGap';

export const getRollGap = (state: RootReducer): DataRollGapState => state.charts.rollgap;

// export const getRollgapLengthX = createSelector(
//   getRollgap,
//   (data: RollGapState) => data.lengthX || []
// );

// export const getRollgaps = createSelector(
//   getRollgap,
//   (data: RollGapState) => data.rollGaps || []
// );

// export const getRollgapRollerPassline = createSelector(
//   getRollgap,
//   (data: RollGapState) => data.rollerPassline || []
// );

// export const getRollgapSegments = createSelector(
//   getRollgap,
//   (data: RollGapState) => data.segmentGapCoorEnd || []
// );

// export const getRollgapCoolzones = createSelector(
//   getRollgap,
//   (data: RollGapState) => data.coolZones || []
// );

// export const getRollgapMaxLengthX = createSelector(
//   getRollgapLengthX,
//   (data: number[]) => max(data)
// );


