import { ModalActionTypes, ModalAction, ModalState } from '../../types/modal';

const initState: ModalState = {  
  activeModal: null
};    
  
export const modalReducer = (state = initState, action: ModalAction): ModalState =>{
  switch (action.type) {
  case ModalActionTypes.OPEN_MODAL:   
    return {  
      ...state,
      activeModal: action.payload
    };
  case ModalActionTypes.CLOSE_MODAL: 
    return { 
      ...state,
      activeModal: null
    };
  default:
    return state;
  }
};  
