import React from 'react';
import moment from 'moment';
import Clock from '../Clock/Clock';
import SMSGroup from '../../assets/images/sms-group.svg';

const CurrentDay: React.FC = () => {
 
  return (  
    <div className="current-day">
      <div className="current-day__logo">
        <img src={SMSGroup} alt="SMS Group" />
      </div>
      <div className="current-day__panel">
        <div className="current-day__group">
          <div className="current-day__day">{moment().format('dddd')}</div>
          <div className="current-day__date">{moment().format('MM.DD.YYYY')}</div>
        </div>
        <div className="current-day__clock">
          <Clock />
        </div>  
      </div>
    </div>
  );
};

export default CurrentDay; 