import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Theme, Box, Button, Container, makeStyles, Typography, createStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import image from '../../assets/images/text.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme)=> createStyles({
  wrapper: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    width: '100vw',
  },
  title: {
    fontFamily: 'Roboto',
    background: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    fontWeight: 900,
    fontSize: '10rem',
    lineHeight: '1em',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: 'calc(90% - 320 * ((100vw - 320px) / 320))',
    }
  }
}));

export default function PageNotFound(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="sm">
        <Typography
          className={classes.title}
          variant='h1'
        >
          404
        </Typography>
        <Typography variant='h5' color='textSecondary' gutterBottom>
          {t('404_SUBTITLE')}
        </Typography>
        <Typography color='textPrimary' gutterBottom>
          {t('404_DESCRIPTION')}
        </Typography>
        <Button
          className={classes.button}
          size='large'
          variant='contained' color='primary' component={RouterLink}
          to='/starter' endIcon={<HomeIcon />}
        >
          {t('404_BUTTON')}
        </Button>
      </Container>
    </Box>
  );
}