import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { defaultOptions } from './defaultOptions';

interface Props {
  // disabled, because there is can be any props.
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  otherOptions?: any,
}

export const SolidificationSegmentsChart: React.FC<Props> = ({ otherOptions }) => {
  const options = { ...defaultOptions, ...otherOptions };

  return (
    <div className="chart-without-yAxis">
      <HighchartsReact
        highcharts={Highcharts}  
        options={options}
      /> 
    </div>
  );
};
