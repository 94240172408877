import React from 'react';
import { Grid } from '@material-ui/core';
import { useLocation } from 'react-router';
import ConfigParser from '../../utils/helper/configParser';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  boardWrapp: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export const Board: React.FC = (): JSX.Element => {
  const location = useLocation();
  const config = ConfigParser.getInstance();
  const components = config.getComponents(location.pathname);
  const classes = useStyles();


  return (
    <div className={classes.boardWrapp}>
      <Grid container spacing={0}>
        {
          !!components && components.map((item, index) => {
            const { col, component: Component } = item;

            return (
              <Grid 
                key={index} 
                item 
                lg={col} 
                md={col}
                sm={col}
                xs={col}
              >
                {!!Component && <Component />}
              </Grid>
            );
          })
        }
      </Grid>
    </div>
  );
};
