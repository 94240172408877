import React from 'react';


interface Props {
  tabID: number | string;
  active: number | string,
  children?: React.ReactNode | React.ReactNode[];
}

export const DefaultTabPanel: React.FC<Props> = ({ tabID, active, children }): JSX.Element => {  
  return (
    <div
      role="tabpanel"
      hidden={tabID !== active}
      id={`default-tabpanel-${tabID}`}
      aria-labelledby={`default-tab-${tabID}`}
    >
      {children}
    </div>
  );
};


