import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const languageKeys = ['en', 'de'];

i18n
  .use(LanguageDetector)
  .use (initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    whitelist: languageKeys,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['queryString', 'cookie'],
      caches: ['cookie']
    },
    returnNull: false, 
    returnEmptyString: false,
  });

export default i18n; 