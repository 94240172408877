import { max } from 'lodash';
import { createSelector } from 'reselect';
import { RootReducer } from '../../..';
import { ShellState } from '../../../types/charts/shell';

export const getShell = (state: RootReducer): ShellState => state.charts.shell;

export const getShellMaxLength = createSelector(
  (shell: ShellState) => shell.data?.Length || [],
  (data: number[]) => max(data)
);
