import { ParameterPanelAction, ParameterPanelActionTypes, ParameterPanelState } from '../../types/parameterPanel';

const initState: ParameterPanelState = {
  tempNo: 7,
  spPlanNo: 3,
  castThickness: 350,
  currentMaterial: 'LC2',
  trProcess: 1400,
  trProcess9: null,
  trProcess10: null,
  trProcess11: null,
  modelState: 'DEFAULT',
};

export const parameterPanelReducer = (
  state = initState, action: ParameterPanelAction
): ParameterPanelState => {
  switch (action.type) {

  case ParameterPanelActionTypes.SET_CASTTHICKNESS:
    return { ...state, castThickness: action.payload };      
  case ParameterPanelActionTypes.SET_TPROCESS:
    return { ...state, trProcess: action.payload };      
  default:
    return state;
    
  }
};