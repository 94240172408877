import { HeaderActionTypes, HeaderAction, HeaderState } from '../../types/header';

const initState: HeaderState = {  
  topMenuIsHide: false 
};    
  
export const headerReducer = (state = initState, action: HeaderAction): HeaderState =>{
  switch (action.type) {
  case HeaderActionTypes.TOGGLE_TOP_MENU: 
    return {  
      ...state, 
      topMenuIsHide: !state.topMenuIsHide
    };
  default:
    return state;
  }
};  
