import React, { useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SolidificationArrowChart } from '../SolidificationArrowChart/SolidificationArrowChart';
import { options as defaultOptions } from './options';
import { defaultOptions as optionsArrowChart } from '../SolidificationArrowChart/defaultOptions'; 
import { useDispatch, useSelector } from 'react-redux';
import NavigationTwoToneIcon from '../../../assets/images/arrow_solid_mini_chart.svg';
import { getTemperaturesChart } from '../../../store/selectors/charts/temperaturesChart/temperaturesChartSelector';
import {
  getSolidificationArrowChartLine1,
  getSolidificationArrowChartLine2,
  getSolidificationArrowChartLine3,
  getSolidificationArrowChartLine4,
  getSolidificationArrowChartLine5,
  // getSolidificationArrowChartArrow,
} from '../../../store/selectors/charts/solidificationArrowChart/solidificationArrowChartSelector';
import zip from 'lodash/zip';
import { generateConstraintLines } from '../../../utils/charts/generateConstraintLines';
import segementMarker from '../../../assets/images/segement_symbol_pink.png';
import { max } from 'lodash';
import { fetchTemperaturesChartStatiLinesRequest } from '../../../store/actions/charts/temperatures/temperaturesActions';
import { Spinner } from '../../common/Spinner/Spinner';
import { makeStyles } from '@material-ui/core';
import { getCommonLines } from '../../../store/selectors/charts/commonLines/commonLinesSelector';
import { setTemperaturesChartData } from '../../../store/actions/charts/temperatures/temperaturesActions';
import Socket from '../../../socket/socket';
import { getSolidificationLength, getStatusLeftPanel } from '../../../store/selectors/leftPanel/leftPanelSelector';
import { getHeaderTopMenuBtnStatus } from '../../../store/selectors/header/headerSelector';
import { getStatusRightPanel } from '../../../store/selectors/rightPanel/rightPanel';

const useStyles = makeStyles({
  spinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute', 
  }
});

export const TemperaturesChart = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const temperaturesChart = useSelector(getTemperaturesChart);
  const commonLines = useSelector(getCommonLines);
  const maxLengthX = useMemo(() => max(temperaturesChart.staticData?.Length), [temperaturesChart.staticData?.Length]);
  // const [connection, setConnection] = useState(null as any);
  // const segmentsData = useSelector(getTemperaturesChartSegments);
  
  // solidificationArrowChartSelector
  const line1MiniChartData = useSelector(getSolidificationArrowChartLine1);
  const line2MiniChartData = useSelector(getSolidificationArrowChartLine2);
  const line3MiniChartData = useSelector(getSolidificationArrowChartLine3);
  const line4MiniChartData = useSelector(getSolidificationArrowChartLine4);
  const line5MiniChartData = useSelector(getSolidificationArrowChartLine5);
  const solidificationLengthData = useSelector(getSolidificationLength);
  // const arrowMiniChartData = useSelector(getSolidificationArrowChartArrow);

  const topMenuIsHide = useSelector(getHeaderTopMenuBtnStatus);
  const rightMenuIsHide = useSelector(getStatusRightPanel);
  const leftMenuIsHide = useSelector(getStatusLeftPanel);

  useEffect(() => {
    dispatch(fetchTemperaturesChartStatiLinesRequest());
    const socket = new Socket();

    socket.start()
      .then(() => {
        socket.emmit('SendSolidification', 'Length,Core,CenterTop,CenterBottom,Mean,MeanShell,Edge,EdgeTop,EdgeSide,SurfaceNarrowSide,LiquidFractionCore,TMaxBulging,MeanShellNarrowSide', 0.999, 1.002)
          .catch(function (err) {
            return console.error('Error Invoke', err.toString());
          }); 
        socket.on(dispatch, setTemperaturesChartData, 'ReceiveSendSolidification');
      });

    return () => {
      socket.disconnect();
    }; 
    
  }, [dispatch]);

 
  const lineCore = useMemo(() => ({ 
    name: 'T Core',
    type: 'spline',
    color: '#ff0000',
    data: temperaturesChart.data?.Core && zip(temperaturesChart.staticData?.Length, temperaturesChart.data?.Core)
  }), [temperaturesChart.data?.Core, temperaturesChart.staticData?.Length]); 

  const lineCenterTop = useMemo(() => ({
    name: 'T Surface Top',
    type: 'spline',
    color: '#0000FF',
    data: temperaturesChart.data?.CenterTop && zip(temperaturesChart.staticData?.Length, temperaturesChart.data?.CenterTop)
  }), [temperaturesChart.data?.CenterTop, temperaturesChart.staticData?.Length]); 

  const lineCenterBottom = useMemo(() => ({
    name: 'T Surf Bottom',
    type: 'spline',
    color: '#b8860b',
    data: temperaturesChart.data?.CenterBottom && zip(temperaturesChart.staticData?.Length, temperaturesChart.data?.CenterBottom)
  }), [temperaturesChart.data?.CenterBottom, temperaturesChart.staticData?.Length]); 

  const lineMean = useMemo(() => ({
    name: 'T Mean',
    type: 'spline',
    color: '#ff00ff',
    data: temperaturesChart.data?.Mean && zip(temperaturesChart.staticData?.Length, temperaturesChart.data?.Mean)
  }), [temperaturesChart.data?.Mean, temperaturesChart.staticData?.Length]); 

  const lineMeanShell = useMemo(() => ({
    name: 'T Mean Shell',
    type: 'spline',
    color: '#bdb76b',
    data: temperaturesChart.data?.MeanShell && zip(temperaturesChart.staticData?.Length, temperaturesChart.data?.MeanShell)
  }), [temperaturesChart.data?.MeanShell, temperaturesChart.staticData?.Length]); 

  const lineMeanShellSide = useMemo(() => ({
    name: 'T Mean Shell Side',
    type: 'spline',
    color: '#9400D3',
    data: temperaturesChart.data?.MeanShellNarrowSide && zip(temperaturesChart.staticData?.Length, temperaturesChart.data?.MeanShellNarrowSide)
  }), [temperaturesChart.data?.MeanShellNarrowSide, temperaturesChart.staticData?.Length]); 

  const lineEdge = useMemo(() => ({
    name: 'T Edge',
    type: 'spline',
    color: '#8FBC8F',
    data: temperaturesChart.data?.Edge && zip(temperaturesChart.staticData?.Length, temperaturesChart.data?.Edge)
  }), [temperaturesChart.data?.Edge, temperaturesChart.staticData?.Length]); 

  const lineEdgeTop = useMemo(() => ({
    name: 'T Edge Top',
    type: 'spline',
    color: '#006400',
    data: temperaturesChart.data?.EdgeTop && zip(temperaturesChart.staticData?.Length, temperaturesChart.data?.EdgeTop)
  }), [temperaturesChart.data?.EdgeTop, temperaturesChart.staticData?.Length]); 

  const lineEdgeSide = useMemo(() => ({
    name: 'T Edge Side',
    type: 'spline',
    color: '#556B2F',
    data: temperaturesChart.data?.EdgeSide && zip(temperaturesChart.staticData?.Length, temperaturesChart.data?.EdgeSide)
  }), [temperaturesChart.data?.EdgeSide, temperaturesChart.staticData?.Length]); 

  const lineSurfaceSide = useMemo(() => ({
    name: 'T Surface Side',
    type: 'spline',
    color: '#008B8B',
    data: temperaturesChart.data?.SurfaceNarrowSide && zip(temperaturesChart.staticData?.Length, temperaturesChart.data?.SurfaceNarrowSide)
  }), [temperaturesChart.data?.SurfaceNarrowSide, temperaturesChart.staticData?.Length]); 

  const lineLF = useMemo(() => ({
    name: 'T LF',
    type: 'spline',
    color: '#00CED1',
    data: temperaturesChart.data?.LiquidFractionCore && zip(temperaturesChart.staticData?.Length, temperaturesChart.data?.LiquidFractionCore)
  }), [temperaturesChart.data?.LiquidFractionCore, temperaturesChart.staticData?.Length]); 

  const lineSolidus = useMemo(() => ({
    name: 'T Solidus',
    type: 'spline',
    color: '#00008B',
    dashStyle: 'shortdot',
    data: temperaturesChart.staticData?.TSolidus && zip(temperaturesChart.staticData?.Length, temperaturesChart.staticData?.TSolidus)
  }), [temperaturesChart.staticData?.TSolidus, temperaturesChart.staticData?.Length]); 

  const lineLiquidus = useMemo(() => ({
    name: 'T Liquidus',
    type: 'spline',
    color: '#00008B',
    dashStyle: 'shortdot',
    data: temperaturesChart.staticData?.TLiquidus && zip(temperaturesChart.staticData?.Length, temperaturesChart.staticData?.TLiquidus)
  }), [temperaturesChart.staticData?.TLiquidus, temperaturesChart.staticData?.Length]); 

  const lineBulging = useMemo(() => ({
    name: 'T Bulging',
    type: 'spline',
    color: '#FFA500',
    data: temperaturesChart.data?.TMaxBulging && zip(temperaturesChart.staticData?.Length, temperaturesChart.data?.TMaxBulging)
  }), [temperaturesChart.data?.TMaxBulging, temperaturesChart.staticData?.Length]); 

  const lineSoll = useMemo(() => ({
    name: 'T Soll',
    type: 'spline',
    color: '#FFA500',
    data: temperaturesChart.soll && zip(temperaturesChart.staticData?.Length, temperaturesChart.soll)
  }), [temperaturesChart.soll, temperaturesChart.staticData?.Length]); 

  const lineStraighteningArea = useMemo(() => ({
    name: 'Straightening Area',
    type: 'column',
    color: '#ff0077', 
    opacity: 0.4,
    pointWidth: 50,
    zIndex: -99,
    data: [[temperaturesChart.straighteningArea, 1600]]
  }), [temperaturesChart.straighteningArea]);

  const redBar1 = useMemo(() => ({
    name: 'redBar1',
    type: 'spline',
    color: 'red', 
    showInLegend: false,
    lineWidth: 40,
    zIndex: 2,
    data: temperaturesChart.redBar1?.map(data => [data, 700]),
  }), [temperaturesChart.redBar1]);

  const redBar2 = useMemo(() => ({
    name: 'redBar2',
    type: 'spline',
    color: 'red', 
    showInLegend: false,
    lineWidth: 40,
    zIndex: 2,
    data: temperaturesChart.redBar2?.map(data => [data, 700]),
  }), [temperaturesChart.redBar2]);

  const heatLine1 = useMemo(() => ({
    name: 'heatLine1',
    type: 'spline',
    color: 'gold',
    showInLegend: false, 
    lineWidth: 20, 
    zIndex: 2,
    data: temperaturesChart.heatLine1?.map(data => [data, 700]),
  }), [temperaturesChart.heatLine1]);

  const heatLine2 = useMemo(() => ({
    name: 'heatLine2',
    type: 'spline',
    color: 'gold',
    showInLegend: false, 
    lineWidth: 20, 
    zIndex: 2,
    data: temperaturesChart.heatLine2?.map(data => [data, 700]),
  }), [temperaturesChart.heatLine2]);

  const coolZone = useMemo(() => ({
    name: 'coolZone',
    type: 'column',
    color: '#2f6fed', 
    maxPointWidth: 1, 
    zIndex: -98,
    data: commonLines?.coolzonePasslineCoor?.map(item => [item, 1600]),
  }), [commonLines?.coolzonePasslineCoor]);


  const segmentLine = useMemo(() => ({
    name: 'Segment Line',
    type: 'column',
    color: '#edc928',
    maxPointWidth: 0.1, 
    zIndex: -99,
    data: commonLines?.segmentPasslineCoor?.map(item => [item, 1600]),
  }), [commonLines?.segmentPasslineCoor]);

  const segmentsIcons = useMemo(() => ({
    name: 'Segments',
    color: '#BA0392',
    type: 'line',
    lineWidth: 0,
    marker: {
      enabled: true,
      symbol: `url(${segementMarker})`,
    },
    zIndex: 99,
    states: {
      hover: {
        enabled: false,
      }
    },
    data: commonLines?.segmentPasslineCoor?.map((data, index) => ({
      type: 'spline',
      x: data,
      y: 620,
      dataLabels: {
        enabled: true, 
        color: '#000',
        align: 'left',
        verticalAlign: 'middle',
        format: `seg. ${index + 1}`,
        shape: null,
        style: {
          fontWeight: 400,
        }
      },
    }))
  }), [commonLines?.segmentPasslineCoor]);

  const solidificationLineSeries = useMemo(() => {
    return generateConstraintLines(solidificationLengthData, 600, 1600, 'Solidification', '#BA0392', 'left');
  }, [solidificationLengthData]);
  const warningLineSeries = useMemo(() => {
    return generateConstraintLines(commonLines?.solidificationWarning, 600, 1600, 'Warning', '#BA0392');
  }, [commonLines?.solidificationWarning]);
  const warmLineSeries = useMemo(() => {
    return generateConstraintLines(commonLines?.solidificationAlarm, 600, 1600, 'Warm', '#BA0392');
  }, [commonLines?.solidificationAlarm]);
  const endOfStrandLineSeries = useMemo(() => {
    return generateConstraintLines(commonLines?.endOfStrendGuide, 600, 1600, 'End Of Strand Guide', '#000000');
  }, [commonLines?.endOfStrendGuide]);

  const tempDistributionOptions = useMemo(() => ({
    ...defaultOptions,
    xAxis: defaultOptions.xAxis.map(item => ({
      ...item,
      max: maxLengthX && Math.round(maxLengthX),
    })),
    series: [
      solidificationLineSeries,
      warningLineSeries,
      warmLineSeries,
      endOfStrandLineSeries,
      lineCore,
      lineCenterTop,
      lineCenterBottom,
      lineMean,
      lineMeanShell,
      lineMeanShellSide,
      lineEdge,
      lineEdgeTop,
      lineEdgeSide,
      lineSurfaceSide,
      lineLF,
      lineSolidus,
      lineLiquidus,
      lineBulging,
      lineSoll,
      lineStraighteningArea,
      redBar1,
      redBar2,
      heatLine1,
      heatLine2,
      coolZone,
      segmentLine,
      segmentsIcons,
    ],
  }), 
  [
    solidificationLineSeries,
    warningLineSeries,
    warmLineSeries,
    endOfStrandLineSeries,
    maxLengthX,
    lineCore, 
    lineCenterTop,
    lineCenterBottom,
    lineMean,
    lineMeanShell,
    lineMeanShellSide,
    lineEdge,
    lineEdgeTop,
    lineEdgeSide,
    lineSurfaceSide,
    lineLF,
    lineSolidus,
    lineLiquidus,
    lineBulging,
    lineSoll,
    lineStraighteningArea,
    redBar1,
    redBar2,
    heatLine1,
    heatLine2,
    coolZone,
    segmentLine,
    segmentsIcons
  ]);

  const line1 = useMemo(() => ({
    name: 'line1',
    type: 'spline',
    color: '#fffd8f', 
    lineWidth: 30,
    data: line1MiniChartData.map(data => [data, 1050])
  }), [line1MiniChartData]);

  const line2 = useMemo(() => ({
    name: 'line2',
    type: 'spline',
    color: '#ebde7f',
    lineWidth: 30,
    data: line2MiniChartData.map(data => [data, 1050])
  }), [line2MiniChartData]);

  const line3 = useMemo(() => ({
    name: 'line3',
    type: 'spline',
    color: '#94602f',
    lineWidth: 30,
    zIndex: 2,
    data: line3MiniChartData.map(data => ({
      x: data,
      y: 1050,
      marker: {
        enabled: true,
        symbol: `url(${NavigationTwoToneIcon})`,
      }
    })) 
  }), [line3MiniChartData]);
  

  const line4 = useMemo(() => ({
    name: 'line4',
    type: 'spline', 
    color: '#ebde7f',
    zIndex: 1,
    lineWidth: 30,
    data: line4MiniChartData.map(data => [data, 1050])
      
  }), [line4MiniChartData]);

  const line5 = useMemo(() => ({
    name: 'line5',
    type: 'spline',
    color: '#43a894',
    lineWidth: 30,
    data: line5MiniChartData.map(data => [data, 950])
  }), [line5MiniChartData]);


  // const arrowMiniChart = useMemo(() => ({
  //   name: 'arrow',
  //   type: 'spline',
  //   color: '#43a894',
  //   lineWidth: 30,
  //   data: {
  //     x: arrowMiniChartData,
  //     y: 1050,
  //   }
  // }), [arrowMiniChartData]);


  const temperaturesMiniChartOptions = useMemo(() => ({
    ...optionsArrowChart,
    title: {
      ...optionsArrowChart.title,
      text: 'Strand Temperatures'
    },
    series: [
      segmentLine,
      coolZone,
      line1,
      line2,
      line3,
      line4,
      line5,
      
    ]
  }), [
    segmentLine,
    coolZone,
    line1,
    line2,
    line3,
    line4,
    line5,
    
  ]);


  if (!temperaturesChart.data) {
    return <div className={classes.spinnerContainer}><Spinner /></div>;
  }

  const styles = topMenuIsHide ? { height: 'calc( 100vh - 50px - 230px )', minHeight: '500px' } : { height: 'calc( 100vh - 200px - 230px )', minHeight: '500px' };
  const chartKey = `${topMenuIsHide}${rightMenuIsHide}${leftMenuIsHide}`;

  return ( 
    <>
      <SolidificationArrowChart key={`${chartKey}_top`} otherOptions={temperaturesMiniChartOptions} />
      <div className="temperatureChart">
        <HighchartsReact 
          key={chartKey}
          containerProps={{ style: styles }} 
          highcharts={Highcharts}  
          options={tempDistributionOptions} 
        />  
      </div>
    </>
  );
};
  