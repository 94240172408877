export const options = {
  chart: {
    type: 'line',
    // height: 650,
  },
  title: {  
    text: 'Waterflow to Casting Speed',
    style: {
      fontSize: '21px',
      fontWeight: '700'
    }
  },
  xAxis: {
    title: {
      text: 'Casting speed m/min',
      style: {
        fontSize: '14px',
        fontWeight: '700',
        color: '#000000'
      }
    },
  },
  yAxis: {
    title: {
      text: 'Waterflow l/min',
      style: {
        fontSize: '14px',
        fontWeight: '700',
        color: '#FC9407'
      }
    },
    min: 0,
    tickInterval: 50,
  },
  legend: {  
    enabled: true,
    layout: 'vertical',
    backgroundColor: '#ECFFFE',
    align: 'right',
    verticalAlign: 'middle',
    borderWidth: 1,
    borderColor: '#D3DCE6',
    floating: true,
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: true,
      },
      marker: {
        symbol: 'circle',
        radius: 3,
      },
      enableMouseTracking: false
    }
  },
};