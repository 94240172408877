export type TypeShellData = {
  ThicknessBottom: number[];
  ThicknessTop: number[];
  CenterNarrowShellThickness: number[];
  Length: number[];
}

export type ShellState = {
  data: TypeShellData | null;
}

export enum ShellActionTypes {
  FETCH_SHELL_DATA_REQUEST = 'FETCH_SHELL_DATA_REQUEST',
  SET_SHELL_DATA = 'SET_SHELL_DATA',
}

type FetchShellDataRequest = {
  type: ShellActionTypes.FETCH_SHELL_DATA_REQUEST,
}

type SetShellData = {
  type: ShellActionTypes.SET_SHELL_DATA,
  payload: TypeShellData
}

export type ShellAction = SetShellData | FetchShellDataRequest;
