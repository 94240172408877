import { ActionDataType } from '../../..';
import { TempDistributionAction, TempDistributionActionsTypes, TypeTempDistribution } from '../../../types/charts/tempDistribution';

export const setTempDistributionData = (data: ActionDataType): TempDistributionAction => ({
  type: TempDistributionActionsTypes.SET_TEMP_DISTRIBUTION_DATA,
  payload: data as TypeTempDistribution, 
}); 
 
export const fetchTempDistributionRequest = (): TempDistributionAction => ({
  type: TempDistributionActionsTypes.FETCH_TEMP_DISTRIBUTION_REQUEST,
});