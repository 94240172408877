import { AlertAction, AlertActionTypes, AlertState } from '../../../types/app/alert';

const initialState: AlertState = {
  message: '',
};

export const alertReducer = (state = initialState, action: AlertAction): AlertState => {
  switch (action.type) {
  case AlertActionTypes.SHOW_ALERT:
    return { ...state, message: action.payload };
  case AlertActionTypes.HIDE_ALERT:
    return { ...state, message: '' };
  default:
    return state;
  }
};