import { AxiosPromise } from 'axios';
import { apiService } from '../index';


export const solidification = {
  getShell(): AxiosPromise<string> {
    return apiService.get('/Main/SoftReductionByFilter?filter=ThicknessTop,ThicknessBottom,CenterNarrowShellThickness,Length');
  },
  getTempDistribution(): AxiosPromise<string> {
    return apiService.get('/Simulation/SimulationWidthTDistributions?filters=HTS_0,HTS_1,HTS_2,HTS_3,HTS_4,HTS_5,HTS_6,HTS_7,TSolidusThicknessValues&minValue=0.999&maxValue=1.002');
  },
  getTemperaturesChart(): AxiosPromise<string> {
    return apiService.get('/Simulation/SimulationSolidification?filters=Length,Core,CenterTop,CenterBottom,Mean,MeanShell,Edge,EdgeTop,EdgeSide,SurfaceNarrowSide,LiquidFractionCore,TMaxBulging,MeanShellNarrowSide&minValue=0.999&maxValue=1.002');
  },
  getTemperaturesChartStaticLines(): AxiosPromise<string> {
    return apiService.get('/Main/SolidificationByFilter?filter=Length,TSolidus,TLiquidus');
  },
}; 
  