import React, { useEffect } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useSelector } from 'react-redux';
import {
  getCastDimensiton,
  getCastingTemperature,
  getCastLength,
  getHeatID,
  getLevel,
  getMode,
  getMoldWaterTemperature,
  getMoldWaterVolume,
  getSolidificationLength,
  getSpeed,
  getSpraywater,
  getStatusLeftPanel,
  getSteelGrade,
} from '../../store/selectors/leftPanel/leftPanelSelector';
import { updateParameters } from '../../utils/updateParameters';
import {
  setCastingTemp,
  setCastLength,
  setIsOpen,
  setMoldWaterTemp,
  setSolidificationLength,
  setSpeed,
  setSpraywater
} from '../../store/actions/leftPanel/leftPanelAction';
import { useAppDispatch } from '../../hooks/useAppDispatch';

const useStyles = makeStyles({
  toggleBtn: {
    minWidth: 50,
    height: 50,
    borderRadius: 0,
  }
});

const AsideLeft: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isOpen = useSelector(getStatusLeftPanel);
  const layoutAside = classNames({
    'layout-aside': true,
    open: isOpen,
  });
  const dispatch = useAppDispatch();
  const modeData = useSelector(getMode);
  const heatIDData = useSelector(getHeatID);
  const castLengthData = useSelector(getCastLength);
  const steelGradeData = useSelector(getSteelGrade);
  const castingTemperatureData = useSelector(getCastingTemperature);
  const castDimensitonData = useSelector(getCastDimensiton);
  const solidificationLengthData = useSelector(getSolidificationLength);
  const speedData = useSelector(getSpeed);
  const spraywaterData = useSelector(getSpraywater);
  const moldWaterVolumeData = useSelector(getMoldWaterVolume);
  const moldWaterTemperatureData = useSelector(getMoldWaterTemperature);
  const levelData = useSelector(getLevel);

  useEffect(() => {
    const timerId = setInterval(() => {
      const castLength = updateParameters(541, 601);
      const castingTemp = updateParameters(1550, 1530, false);
      const speed = updateParameters(1.1, 1.3);
      const spraywater = updateParameters(3530, 3560, false);
      const moldWaterTemp = updateParameters(7.8, 8);
      const solidificationLength = updateParameters(25.7, 26);

      const newCastingTemp = castingTemperatureData ? [castingTemp, castingTemperatureData[1]] : [];
      const newMoldWaterTemp = moldWaterTemperatureData ? [moldWaterTemp, moldWaterTemperatureData[1]] : [];

      batch(() => {
        dispatch(setCastLength(castLength));
        dispatch(setCastingTemp(newCastingTemp));
        dispatch(setSpeed(speed));
        dispatch(setSpraywater(spraywater));
        dispatch(setMoldWaterTemp(newMoldWaterTemp));
        dispatch(setSolidificationLength(solidificationLength));
      });
    }, 3000);

    return () => {
      clearInterval(timerId);
    };
  }, [castingTemperatureData, dispatch, moldWaterTemperatureData]);

  const handleToggle = () => dispatch(setIsOpen(!isOpen));

  const DataListSidebar = [
    { title: t('SB_MODE'), dataValue: modeData },
    { title: t('SB-HEAT-ID'), dataValue: heatIDData },
    { title: `${t('SB-CASTLENGTH')} / m`, dataValue: castLengthData },
    { title: t('SB-STEELGRADE'), status: 'Peritect', dataValue: steelGradeData },
    { title: `${t('SB-CASTING-TEMP')} / °C`, dataValue: `${castingTemperatureData[0]} / ${castingTemperatureData[1]}` },
    { title: `${t('SB-STRAND-DIM')} / mm`, dataValue: `${castDimensitonData[0]} x ${castDimensitonData[1]}` },
    { title: `${t('SOLIDIFICATION_LENGTH')} / m`, dataValue: solidificationLengthData },
    { title: `${t('SB-SPEED')} / m/min`, dataValue: speedData },
    { title: `${t('SB-SPWATER-FLOW')} / l/m`, dataValue: spraywaterData },
    { title: `${t('MOLD-WATER-VOLUME')}/ I/min`, dataValue: `${moldWaterVolumeData[0]} x ${moldWaterVolumeData[1]}` },
    {
      title: `ΔT ${(t('MOLD_WATER_TEMP'))}/ Δ°C`,
      dataValue: `${moldWaterTemperatureData[0]} / ${moldWaterTemperatureData[1]}`
    },
    { title: `${t('SB-MOLDLEVEL')} / mm`, dataValue: levelData },
  ];

  return (
    <div className={layoutAside}>
      <div className="layout-aside__inner">
        <div className="aside-panel">
          <div className="vertical-autoscroll">
            <div className="aside-panel_inner">
              <strong className="aside-panel__title">{t('SB-PROCESSOVERVIEW')}</strong>
              <div className="list-parameters">
                {DataListSidebar.map((item, i: number) =>
                  <div className="list-parameters__item" key={i}>
                    <div className="list-parameters__panel">
                      <span className="list-parameters__name">{t(item.title)}</span>
                      {item.dataValue || item.dataValue == 0 ? (
                        <span className="list-parameters__dataValue">{item.dataValue}</span>) : null}
                      {/* {item.symbol ? (<span className="list-parameters__value">{item.symbol}</span>) : null } */}
                      {item.status ? (<span className="list-parameters__status">{item.status}</span>) : null}
                    </div>
                  </div>
                )
                }
              </div>
            </div>
          </div>
        </div>
        <div className="aside-line">
          <div className="vertical-autoscroll">
            <Button
              className={classes.toggleBtn}
              onClick={handleToggle}
              disableFocusRipple
              disableRipple
            >
              {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </Button>
            <div className="aside-menu">
              <div className="aside-menu__item">
                <Button
                  color="inherit"
                  className="aside-menu__link active"
                >
                  {t('PROCESS-PARAMETER')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsideLeft;