import React from 'react';
import Grid from '@material-ui/core/Grid';
import { DefaultTabs } from '../../components/common/DefaultTabs/DefaultTabs';
import { DefaultTabPanel } from '../../components/common/DefaultTabs/DefaultTabPanel/DefaultTabPanel';
import FormRegistration from '../../components/FormRegistration/FormRegistration';
import { makeStyles } from '@material-ui/core';
import RotationMoment from '../../components/RotationMoment/RotationMoment';
import { useTranslation } from 'react-i18next';
import { DefaultDataGrid } from '../../components/common/DefaultDataGrid/DefaultDataGrid';

const useStyles = makeStyles(() => ({   
  wrapperPanel: {
    height: '100%',
    margin: '12px 15px 20px 12px',
    border: '1px solid #e3e3e3',
    borderRadius: '10px',
 
    '& .MuiTabs-fixed': { 
      display: 'flex',
    }, 
 
    '& .MuiTab-root': {
      minWidth: '100px',
      minHeight: '37px',  
    },

    '& .MuiInput-underline:before,.MuiInput-underline:after': {
      content: 'none!important',
    },
    
  },
  table: {
    width: '100%',
    height: 500
  }
}));

export const UserManagement: React.FC = () => {

  const { t } = useTranslation(); 

  const classes = useStyles();
 
  const tabsArr = [
    {
      id: 'SPVC',
      label: t('SPVC'),
    },
    {
      id: 'ROTATION_MOMENT',
      label: t('ROTATION_MOMENT'),
    },
    {
      id: 'USER_INFO',
      label: t('USER_INFO'),
    },
    {
      id: 'LOCALES',
      label: t('LOCALES'),
    },
  ];



  const userManagmentTabs = [
    {
      id: 'USERS',
      label: t('USERS'),
            
    },
    {
      id: 'ROLE', 
      label: t('ROLE'),
    },
    {
      id: 'PERMISSION',
      label: t('PERMISSION'),
    },
  ];

  const itemsTable1 = [
    {
      id: 1,
      name: 'Marty',
      password: 'password',
      secure_pass: 'securepass',
      session_id: 0,
      user_role: 'Admin',
      locale: 'de-DE' 
    },
    {
      id: 2,
      name: 'Marty',
      password: 'password',
      secure_pass: 'securepass',
      session_id: 0,
      user_role: 'Admin',
      locale: 'de-DE'
    },
    {
      id: 3,
      name: 'Marty',
      password: 'password',
      secure_pass: 'securepass',
      session_id: 0,
      user_role: 'Admin',
      locale: 'de-DE'
    },
    {
      id: 4,
      name: 'Marty',
      password: 'password',
      secure_pass: 'securepass',
      session_id: 0,
      user_role: 'Admin',
      locale: 'de-DE'
    },
  ];

  const itemsTable2 = [
    {
      id: 1,
      name: 'Marty',
      password: 'password',
      secure_pass: 'securepass',
      session_id: 0,
      user_role: 'Admin',
      locale: 'de-DE'
    },
    {
      id: 2,
      name: 'Marty',
      password: 'password',
      secure_pass: 'securepass',
      session_id: 0,
      user_role: 'Admin',
      locale: 'de-DE'
    },
  ];

  const itemsTable3 = [
    {
      id: 1,
      name: 'Marty',
      password: 'password',
      secure_pass: 'securepass',
      session_id: 0,
      user_role: 'Admin',
      locale: 'de-DE'
    },
    {
      id: 2,
      name: 'Marty',
      password: 'password',
      secure_pass: 'securepass',
      session_id: 0,
      user_role: 'Admin',
      locale: 'de-DE'
    },
  ];



  const AboutTabsId = {
    SPVC: 'SPVC',
    ROTATION_MOMENT: 'ROTATION_MOMENT',
    USER_INFO: 'USER_INFO',
    LOCALES: 'LOCALES'
  };

  const UserManagmentTabsId = {
    USERS: 'USERS', 
    ROLE: 'ROLE',
    PERMISSION: 'PERMISSION'
  };

  const headers = [
    { field: 'id', headerName: 'ID', flex: 1, editable: true },
    { field: 'name', headerName: 'Name', flex: 1, editable: true },
    { field: 'secure_pass', headerName: 'Secure Pass', flex: 1, editable: true },
    { field: 'session_id', headerName: 'Session ID', flex: 1, editable: true },
    { field: 'user_role', headerName: 'User role', flex: 1, editable: true },
    { field: 'locale', headerName: 'Locale', flex: 1, editable: true },
  ];

  return (
    <div className={classes.wrapperPanel}> 
      <DefaultTabs
        tabs={tabsArr}
        defaultActive={AboutTabsId.ROTATION_MOMENT}
        rounded={10}
        hideTabsContentPadding={15}
        renderContent={(active) => (
          <>
            <DefaultTabPanel tabID={AboutTabsId.SPVC} active={active}>
              SPVC 
            </DefaultTabPanel>
            <DefaultTabPanel tabID={AboutTabsId.ROTATION_MOMENT} active={active}>
              <RotationMoment />
            </DefaultTabPanel>
            <DefaultTabPanel tabID={AboutTabsId.USER_INFO} active={active}>
              <Grid container alignItems="flex-start" spacing={1}>
                <Grid item lg={7}>
                  <DefaultTabs
                    tabs={userManagmentTabs}
                    defaultActive={UserManagmentTabsId.USERS}
                    rounded={10}
                    backgroundTabsPanel={'none'}
                    backgroundTab={'#FFF'}
                    gutters={2.5}
                    disabledIndicator
                    hideTabsContentPadding={0}
                    colorTextTab={'#3B3E43'}
                    renderContent={(active) => (
                      <>
                        <DefaultTabPanel tabID={UserManagmentTabsId.USERS} active={active}>
                          <div className={classes.table}>
                            <DefaultDataGrid columns={headers} rows={itemsTable1} />
                          </div> 
                        </DefaultTabPanel> 
                        <DefaultTabPanel tabID={UserManagmentTabsId.ROLE} active={active}>
                          <div className={classes.table}>
                            <DefaultDataGrid columns={headers} rows={itemsTable2} />
                          </div>
                        </DefaultTabPanel>
                        <DefaultTabPanel tabID={UserManagmentTabsId.PERMISSION} active={active}>
                          <div className={classes.table}>
                            <DefaultDataGrid columns={headers} rows={itemsTable3} />
                          </div>
                        </DefaultTabPanel>
                      </>
                    )}
                  /> 
                </Grid>
                <Grid item lg={5}>
                  <FormRegistration />
                </Grid>
              </Grid>
            </DefaultTabPanel>  
            <DefaultTabPanel tabID={AboutTabsId.LOCALES} active={active}>
              {t('LOCALES_INFO')}
            </DefaultTabPanel>
          </>
        )}
      />
    </div>
  );
}; 

