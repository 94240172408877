import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import makeStyles from '@material-ui/styles/makeStyles';

interface StyleProps {
  gutters?: number,
  backgroundTabsPanel?: string,
  backgroundTab?: string,
  colorIndicator?: string,
  rounded?: number,
  hideTabsContentPadding?: number,
  colorTextTab?: string
}

interface Props extends StyleProps {
  tabs: { id: string | number; label: string; }[],
  defaultActive: string | number,
  disabledIndicator?: boolean,
  renderContent: (active: number | string) => JSX.Element,
}

const useStyles = makeStyles({
  tabs: {
    backgroundColor: (props: StyleProps) => props.backgroundTabsPanel || '#3B3E43',
    color: '#fff',
    borderTopRightRadius: (props: StyleProps) => props.rounded || 0,
    borderTopLeftRadius: (props: StyleProps) => props.rounded || 0,
  },
  tab: {
    textTransform: 'none',
    fontWeight: 500,
    margin: (props: StyleProps) => `0  ${props.gutters}px`,
    backgroundColor: (props: StyleProps) => props.backgroundTab || 'transparent',
    borderTopLeftRadius: (props: StyleProps) => props.rounded || 0,
    borderTopRightRadius: (props: StyleProps) => props.rounded || 0,
    color: (props: StyleProps) => props.colorTextTab || '#E4E4E4',

  },
  tabsContent: {
    padding: (props: StyleProps) =>  `${props.hideTabsContentPadding}px` || '25px 15px',
    backgroundColor: (props: StyleProps) => props.backgroundTab || 'transparent',
    borderTopRightRadius: (props: StyleProps) => props.rounded || 0,
    borderBottomLeftRadius: (props: StyleProps) => props.rounded || 0,
    borderBottomRightRadius: (props: StyleProps) => props.rounded || 0,
  },
  tabsContainer: {
    margin: (props: StyleProps) => `0  -${props.gutters}px`,
  },
  tabActiveIndicator: {
    backgroundColor: (props: StyleProps) => props.colorIndicator || '#E2001A',
  },
});

export const DefaultTabs: React.FC<Props> = ({
  tabs, 
  rounded,
  defaultActive,
  renderContent,
  backgroundTabsPanel,
  backgroundTab,
  colorIndicator,
  gutters,
  disabledIndicator,
  hideTabsContentPadding,
  colorTextTab

}): JSX.Element => {
  const [active, setActive] = useState(defaultActive);
  const classes = useStyles({
    backgroundTabsPanel,
    backgroundTab,
    gutters,
    colorIndicator,
    rounded,
    hideTabsContentPadding,
    colorTextTab
  });

  const handleChange = (value: number | string): void => {
   
    setActive(value);
  };
  return (
    <>
      <div className="default-tabs">
        <Tabs
          className={classes.tabs}
          classes={{ flexContainer: classes.tabsContainer }}
          value={active}
          TabIndicatorProps={{ className: classes.tabActiveIndicator, hidden: disabledIndicator }}
          onChange={(_, value) => handleChange(value)}
        >
          {
            tabs.map(tab => (
              <Tab 
                key={tab.id}
                id={`simple-tab-${tab.id}`}
                className={classes.tab}
                value={tab.id}
                label={tab.label}
                disableFocusRipple
                disableRipple
              />
            ))
          }
       
        </Tabs>
        <div>
        </div>
      </div>
      <div className={classes.tabsContent}>
        {renderContent(active)}
      </div>
    </>
  );
};

