import { WaterflowAction, WaterflowActionTypes, WaterflowSate } from '../../../types/charts/waterflow';

const initState: WaterflowSate = {
  selected: null,
  data: [
    {
      id: 1,
      ctrl: 1,
      loopName: 1,
      min: 92,
      max: 350,
      water1: 92,
      water2: 92,
      water3: 92,
      water4: 92,
      water5: 92,
      water6: 104,
    },
    {
      id: 2,
      ctrl: 2,
      loopName: 2,
      min: 43,
      max: 170,
      water1: 43,
      water2: 43,
      water3: 43,
      water4: 43,
      water5: 43,
      water6: 51,
    },
    {
      id: 3,
      ctrl: 3,
      loopName: 3,
      min: 24,
      max: 360,
      water1: 24,
      water2: 24,
      water3: 46,
      water4: 59,
      water5: 72,
      water6: 105,
    },
    {
      id: 4,
      ctrl: 4,
      loopName: 3.1,
      min: 10,
      max: 145,
      water1: 10,
      water2: 10,
      water3: 18,
      water4: 24,
      water5: 29,
      water6: 42,
    },
    {
      id: 5,
      ctrl: 5,
      loopName: 3.2,
      min: 10,
      max: 145,
      water1: 10,
      water2: 10,
      water3: 18,
      water4: 24,
      water5: 29,
      water6: 42,
    },
    {
      id: 6,
      ctrl: 6,
      loopName: 3.3,
      min: 10,
      max: 145,
      water1: 10,
      water2: 10,
      water3: 18,
      water4: 24,
      water5: 29,
      water6: 42,
    },
    {
      id: 7,
      ctrl: 7,
      loopName: 3.4,
      min: 10,
      max: 145,
      water1: 10,
      water2: 10,
      water3: 18,
      water4: 24,
      water5: 29,
      water6: 42,
    },
    {
      id: 8,
      ctrl: 8,
      loopName: 4,
      min: 43,
      max: 170,
      water1: 43,
      water2: 43,
      water3: 43,
      water4: 43,
      water5: 43,
      water6: 51,
    },
    {
      id: 9,
      ctrl: 9,
      loopName: 4.1,
      min: 43,
      max: 170,
      water1: 43,
      water2: 43,
      water3: 43,
      water4: 43,
      water5: 43,
      water6: 51,
    },
    {
      id: 10,
      ctrl: 10,
      loopName: 4.2,
      min: 43,
      max: 170,
      water1: 43,
      water2: 43,
      water3: 43,
      water4: 43,
      water5: 43,
      water6: 51,
    },
    {
      id: 11,
      ctrl: 11,
      loopName: 4.3,
      min: 43,
      max: 170,
      water1: 43,
      water2: 43,
      water3: 43,
      water4: 43,
      water5: 43,
      water6: 51,
    },
    {
      id: 12,
      ctrl: 12,
      loopName: 4.4,
      min: 43,
      max: 170,
      water1: 43,
      water2: 43,
      water3: 43,
      water4: 43,
      water5: 43,
      water6: 51,
    },
    {
      id: 13,
      ctrl: 13,
      loopName: 5,
      min: 43,
      max: 170,
      water1: 43,
      water2: 43,
      water3: 43,
      water4: 43,
      water5: 43,
      water6: 51,
    },
    {
      id: 14,
      ctrl: 14,
      loopName: 5.1,
      min: 43,
      max: 170,
      water1: 43,
      water2: 43,
      water3: 43,
      water4: 43,
      water5: 43,
      water6: 51,
    },
    {
      id: 15,
      ctrl: 15,
      loopName: 5.2,
      min: 43,
      max: 170,
      water1: 43,
      water2: 43,
      water3: 43,
      water4: 43,
      water5: 43,
      water6: 51,
    }
  ]
};

export const waterflowReducer = (state = initState, action: WaterflowAction): WaterflowSate => {
  switch (action.type) {
  case WaterflowActionTypes.SELECT_WATERFLOW_ITEM:
    return {
      ...state,
      selected: action.payload
    };
  default:
    return state;
  }
};