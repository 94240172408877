import { WaterSpecificAction, WaterSpecificActionsTypes, TypeWaterSpecificData } from '../../../types/charts/waterSpecific';

export const setWaterSpecificData = (data: TypeWaterSpecificData): WaterSpecificAction => ({
  type: WaterSpecificActionsTypes.SET_WATER_SPECIFIC_DATA,
  payload: data, 
}); 
 
export const fetchWaterSpecificRequest = (): WaterSpecificAction => ({
  type: WaterSpecificActionsTypes.FETCH_WATER_SPECIFIC_REQUEST,
});
  
