
export type TypeTemperaturesChart = {
  Core: number[] | null,
  CenterTop: number[] | null,
  CenterBottom: number[] | null,
  Mean: number[] | null,
  MeanShell: number[] | null,
  Edge: number[] | null,
  EdgeTop: number[] | null,
  EdgeSide: number[] | null,
  SurfaceNarrowSide: number[] | null,
  LiquidFractionCore: number[] | null,
  TMaxBulging: number[] | null,
  MeanShellNarrowSide: number[] | null
}

export type TypeTemperaturesChartStaticLines = {
  Length: number[] | null,
  TSolidus: number[] | null,
  TLiquidus: number[] | null,
}
 

export interface DataTemperaturesChartState {
  data: TypeTemperaturesChart | null,
  staticData: TypeTemperaturesChartStaticLines | null,
  straighteningArea: number | null,
  redBar1: number[] | null,
  redBar2: number[] | null, 
  heatLine1: number[] | null,
  heatLine2: number[] | null,
  soll: number[] | null,

}


export enum TemperaturesChartActionsTypes {
  SET_TEMPERATURES_CHART_DATA = 'SET_TEMPERATURES_CHART_DATA',
  FETCH_TEMPERATURES_CHART_REQUEST = 'FETCH_TEMPERATURES_CHART_REQUEST',
  SET_TEMPERATURES_CHART_STATIC_LINES_DATA = 'SET_TEMPERATURES_CHART_STATIC_LINES_DATA',
  FETCH_TEMPERATURES_CHART_STATIC_LINES_REQUEST = 'FETCH_TEMPERATURES_CHART_STATIC_LINES_REQUEST',
}
 

type FetchTemperaturesChartRequest = {
  type: TemperaturesChartActionsTypes.FETCH_TEMPERATURES_CHART_REQUEST
};

type FetchTemperaturesChartStaticLinesRequest = {
  type: TemperaturesChartActionsTypes.FETCH_TEMPERATURES_CHART_STATIC_LINES_REQUEST
};

type SetTemperaturesChartData = {
  type: TemperaturesChartActionsTypes.SET_TEMPERATURES_CHART_DATA,
  payload: TypeTemperaturesChart  
}

type SetTemperaturesChartStaticLinesData = {
  type: TemperaturesChartActionsTypes.SET_TEMPERATURES_CHART_STATIC_LINES_DATA,
  payload: TypeTemperaturesChartStaticLines  
}
 
export type TemperaturesChartAction = 
FetchTemperaturesChartRequest |
SetTemperaturesChartData |
FetchTemperaturesChartStaticLinesRequest |
SetTemperaturesChartStaticLinesData;