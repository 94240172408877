import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { IRoute } from './interfaces/IRoute';
import { Redirect } from 'react-router';

interface Props { 
  routes: IRoute[];
}

const Routes: React.FC<Props> = ({ routes }) => {
  return (
    <Switch>
      {
        routes.map(route => (
          <Route 
            path={route.path}
            key={route.title}
            exact={route.exact}
            render={() => route.render}
          />
        ))
      }
      <Redirect
        path="*" to='/404'
      />
    </Switch>
  );
};

export default Routes;
