import React from 'react';
import { useTranslation } from 'react-i18next';
interface Props {
  label: string;
  value: string | number | null;
  dimension?: string;
}

export const Parameter: React.FC<Props> = ({ label, value, dimension }) => {

  const { t } = useTranslation(); 

  return (
    <div className="parameter-item">
      <div className="parameter-item__label">{t(label)}</div>
      <div className="parameter-item__body">
        {value && <div className="parameter-item__text">{value}</div>}
        {dimension ? <div className="parameter-item__dimension">{dimension}</div> : null }
      </div>
    </div> 
  );
};

 