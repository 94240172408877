import { makeStyles } from '@material-ui/core';
import { DataGrid, DataGridProps } from '@material-ui/data-grid';
import React from 'react';
import { TextAlignProperty } from 'csstype';
 
interface StyleProps {
  colorTitle?: string,
  textAlignTitle?: TextAlignProperty,
  fontWeightTitle?: number, 
  isUppercaseTitle?: boolean,  
  heightTable?: number | string,
  selectedRows?: boolean;
  enablePointerOnRowHover?: boolean;
} 

interface Props extends StyleProps, DataGridProps {
  title?: string  
}
 
const useStyles = makeStyles({   
  table: {
    background: '#fff',
    borderRadius: 8,
    border: 0,
    boxShadow: '0 2px 4px rgba(0, 0, 0, .15)', 
    '& .Mui-odd': {
      background: (props: StyleProps) => props.selectedRows ? '#E9EEF0' : 'transparent',
    },
    '& .MuiDataGrid-cell': {
      '&:focus-within': {
        outline: 'none',
      }
    },
    '& .MuiDataGrid-row': {
      cursor: (props: StyleProps) => props.enablePointerOnRowHover ? 'pointer' : 'default',
    }
  },
  title: {
    marginBottom: 15,
    fontSize: 14,
    fontWeight: (props: StyleProps) => props.fontWeightTitle || 700,
    lineHeight: '16px', 
    textTransform: (props: StyleProps) => props.isUppercaseTitle ? 'uppercase' : 'none',
    textAlign: (props: StyleProps) => props.textAlignTitle || 'center',
    color: (props: StyleProps) => props.colorTitle, 
  }
  
}); 

export const  DefaultDataGrid: React.FC<Props> = (props): JSX.Element => {

  const { 
    title, 
    fontWeightTitle, 
    isUppercaseTitle, 
    textAlignTitle, 
    colorTitle, 
    selectedRows,
    enablePointerOnRowHover
  } = props;
  
  const classes = useStyles({
    fontWeightTitle,
    isUppercaseTitle, 
    textAlignTitle, 
    colorTitle,
    selectedRows,
    enablePointerOnRowHover,
  });

 
  
  return (
    <div style={{ height: '500px' }}> 
      {title && <div className={classes.title}>{title}</div>}
      <DataGrid
        rowHeight={40}
        className={classes.table}  
        {...props}  
      /> 
    </div>
  );  
}; 
