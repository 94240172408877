export const getCookie = (name: string): string | undefined => {
  const matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\\.$?*|{}\\(\\)\\[\]\\\\/\\+^])/g, '\\$1') + '=([^;]*)'
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

interface ICookieOptions {
  expires?: Date | string | number;
  path?: string;
  domain?: string;
  'max-age'?: number;
  samesite?: string;
  secure?: boolean;
}

export const setCookie = (name: string, value: string, options: ICookieOptions = {}): void => {

  options = {
    path: '/',
    expires: new Date(Date.now() + 9.468e11).toUTCString(),
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey as keyof ICookieOptions];
    if (optionValue !== true) {
      updatedCookie += `= ${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
};

export const deleteCookie = (name: string): void => setCookie(name, '', { 'max-age': -1 });

