 
export const defaultOptions = {
  chart: {
    type: 'column', 
    spacingTop: 20,
    spacingBottom: 40,
    // height: 580,
    ignoreHiddenSeries: false,
  },  
  plotOptions: {
    column: {
      pointPadding: 0,
      groupPadding: 0,
      pointPlacement: 'on',
      pointStart: 0,
      pointWidth: 60,
      dataLabels: {
        enabled: true,
        color: '#FFFFFF', 
        inside: true,
        align: 'center',
        backgroundColor: '#dedede',
        borderRadius: 10, 
        verticalAlign: 'middle',
        format: '{point.y:.2f}', 
        style: {
          textOutline: 'none' 
        },
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 5,
        }, 
      }
    },
    series: {
      dataLabels: {
        align: 'center',
        enabled: true,
        backgroundColor: '#dedede',
        borderRadius: 10, 
        color: '#000', 
        verticalAlign: 'middle',
        format: '{point.y:.2f}', 
        style: {
          textOutline: 'none' 
        },
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 5,
        }, 
      }
    },
  },
  title: {
    text: 'Air Pressure and Air Flow',
    align: 'center', 
    margin: 0, 
    style: {
      fontWeight: 'bold'
    }
  },
  credits: {
    enabled: false
  },
  legend: {  
    enabled: true,
    x: -70,
    layout: 'vertical',
    backgroundColor: '#ECFFFE',
    align: 'right',
    verticalAlign: 'middle',
    borderWidth: 1,
    borderColor: '#D3DCE6',
    floating: true,
  },
  xAxis: [
    {
      crosshair: true,
      tickInterval: 1, 
      min: 0,
      max: 19,
      // tickmarkPlacement: 'on',
      // showEmpty: false,
      title: {
        text: 'Air Control Loop/ #',
        style: {
          fontWeight: 'bold',
          fontSize: '18px',
          color: '#000' 
        }
      },
    },
    {
      crosshair: true,
      tickInterval: 1, 
      min: 0,
      max: 10,
      opposite: true,
    },
  ],
  yAxis: [
    { // left y axis
      visible: true,
      min: 0,
      max: 5,
      tickInterval: 0.5,
      title: {
        text: 'Air Pressure/ bar',
        style: {
          color: 'blue',
          fontSize: '16px',
          fontWeight: 'bold'
        }
      },
    },
    { // right y axis
      min: 0,
      max: 240, 
      tickInterval: 20,
      opposite: true,
      visible: true, 
      title: {
        text: 'Air Flow/ Nm³ /min ',
        style: {
          color: 'grey',
          fontSize: '16px',
          fontWeight: 'bold',
        }
      },
      showFirstLabel: false
    }],

  responsive: {
    rules: [{
      condition: {
        maxWidth: 1200
      },
      chartOptions: {
        plotOptions: {
          column: {
            pointWidth: 50,
          },
        },
      }
    }]
  }
};
