export enum HeaderActionTypes {
    TOGGLE_TOP_MENU = 'TOGGLE_TOP_MENU'
}

export interface HeaderState {
    topMenuIsHide: boolean | null
} 

interface ToggleTopMenu {
    type: HeaderActionTypes.TOGGLE_TOP_MENU,
}

export type HeaderAction = ToggleTopMenu;  