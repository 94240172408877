import { ILogin } from '../../../../interfaces/ILogin';

export enum AuthActionTypes {
  LOG_IN = 'LOG_IN',
  LOG_OUT = 'LOG_OUT',
  LOG_TRY = 'LOG_TRY',
}

export type IsLoggedType = true | false;

export type AuthState = {
  isLogged: IsLoggedType;
}

type SetLogIn = {
  type: AuthActionTypes.LOG_IN;
}

type SetLogOut = {
  type: AuthActionTypes.LOG_OUT;
}

export type TryLogIn = {
  type: AuthActionTypes.LOG_TRY;
  payload: ILogin,
}

export type AuthAction = SetLogIn | SetLogOut | TryLogIn;