import { IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MODAL } from '../../constants/Modals/modalsTypes';
import { openModalAction } from '../../store/actions/modal/modalAction';
import GroupIcon from '@material-ui/icons/Group';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Fade from '@material-ui/core/Fade';
import PublicIcon from '@material-ui/icons/Public';
import { useAppDispatch } from '../../hooks/useAppDispatch';


const useStyles = makeStyles({
  userTools: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  languageIcon: {
    background: 'none',
    padding: 0,
  },

  languageBtn: {
    color: 'rgb(49, 49, 49)',
  }

});

export const UserTools: React.FC = (): JSX.Element => {

  const { t, i18n } = useTranslation();

  const classes = useStyles({});

  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = (language: string) => {
    if (typeof (language) === 'string') {
      i18n.changeLanguage(language);
    }
    setAnchorEl(null);

  };

  return (
    <ul className={classes.userTools}>
      <li className="user-tools__item">
        <div className="user-tools__name">{t('USER_TOOLS_NAME')}</div>
      </li>
      <li className="user-tools__item" >
        <IconButton
          color="inherit" component="span"
          disabled
        >
          <GroupIcon />
        </IconButton>
      </li>
      <li className="user-tools__item" >
        <IconButton
          color="inherit"
          component="span"
          onClick={() => dispatch(openModalAction(MODAL.ABOUT))}
        >
          <HelpOutlineIcon />
        </IconButton>
      </li>
      <li className="user-tools__item" >
        <IconButton
          color="inherit"
          component="span"
          onClick={() => dispatch(openModalAction(MODAL.CONFIGURATION))}
        >
          <SettingsIcon />
        </IconButton>
      </li>
      <li className="user-tools__item" >
        <IconButton
          color="inherit" component="span"
          disabled
        >
          <FlashOnIcon />
        </IconButton>
      </li>
      <li className="user-tools__item" >
        <IconButton
          color="inherit" component="span"
          disabled
        >
          <VisibilityIcon />
        </IconButton>
      </li>
      <li className="user-tools__item" >
        <IconButton
          color="inherit" component="span"
          disabled
        >
          <AutorenewIcon />
        </IconButton>
      </li>
      <li className="user-tools__item" >
        <IconButton
          color="inherit" component="span"
          className={classes.languageIcon}
        >
          <div>
            <IconButton
              className={classes.languageBtn} aria-controls="fade-menu" aria-haspopup="true"
              onClick={handleClick}
            >
              <PublicIcon />
            </IconButton>
            <Menu
              id="fade-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => handleClose('en')} >{t('ENGLISH')}</MenuItem>
              <MenuItem onClick={() => handleClose('de')} >{t('GERMAN')}</MenuItem>
            </Menu>
          </div>
        </IconButton>
      </li>
      <li className="user-tools__item" >
        <IconButton
          color="inherit" component="span"
          disabled
        >
          <CloseIcon />
        </IconButton>
      </li>

    </ul>
  );
};
