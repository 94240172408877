import React, { useState, useEffect } from 'react';
import { Parameter } from '../Parameter/Parameter';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { getParameterPanelData } from '../../store/selectors/parameterPanel/parameterPanelSelector';
import { updateParameters } from '../../utils/updateParameters';
import { setCastThicknessData, setTProcessData } from '../../store/actions/parameterPanel/parameterPanelAction';
import { getSolidificationLength, getSpeed } from '../../store/selectors/leftPanel/leftPanelSelector';
import moment from 'moment';


export const ParameterPanel: React.FC = () => {
  const { t } = useTranslation();
  const parameterPanelData = useSelector(getParameterPanelData);
  const solidificationLengthData = useSelector(getSolidificationLength);
  const speedData = useSelector(getSpeed);
  const dispatch = useDispatch();


  const [date, setDate] = useState(new Date());
 
  useEffect(() => {
    
    const timerID = setInterval(() => setDate(new Date()), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  
  const dataParameter = [
    { label: t('TEMP-NO'), value: parameterPanelData.tempNo, dimension: '' },
    { label: t('SP-PLAN-NO'), value: parameterPanelData.spPlanNo, dimension: '' },
    { label: t('VC'), value: speedData, dimension: 'm/min' },
    { label: t('CAST_THICKNESS'), value: parameterPanelData.castThickness, dimension: 'mm' },
    { label: t('CURRENT_MATERIAL'), value: parameterPanelData.currentMaterial },
    { label: t('PROCESS_TIME'), value: `${moment().format('MM.DD.YYYY')} ${date.toLocaleTimeString()}` },
    { label: t('SOLIDIFICATION_LENGTH'), value: solidificationLengthData, dimension: 'm' },
    { label: t('TPROCESS'), value: parameterPanelData.trProcess, dimension: '°C' },
    { label: t('TPROCESS9'), value: parameterPanelData.trProcess9, dimension: '' },
    { label: t('TPROCESS10'), value: parameterPanelData.trProcess10, dimension: '' },
    { label: t('TPROCESS11'), value: parameterPanelData.trProcess11, dimension: '' },
    { label: t('MODEL_STATE'), value: parameterPanelData.modelState, dimension: '' },
  ];

  useEffect(() => {
    const timerId = setInterval(() => {
      const castThicknessData = updateParameters(350, 360);
      const tProcessData = updateParameters(1400, 1500);

      batch(() => {
        dispatch(setCastThicknessData(castThicknessData));
        dispatch(setTProcessData(tProcessData));
      });
    }, 3000);
    
    return () => {
      clearInterval(timerId);
    };
  }, [dispatch]);

  return ( 
    <div className="parameter-panel">
      <div className="parameter">
        {
          dataParameter.map((item, i: number) =>
            <div key={i} className="parameter-box">
              <Parameter 
                label={item.label}
                value={item.value}
                dimension={item.dimension}
              />
            </div>
          )
        }
      </div>
    </div>
  );
};
