import React from 'react';
import { Modal } from '../common/Modal/Modal';
import { MODAL } from '../../constants/Modals/modalsTypes';
import { Configuration } from '../Configuration/Configuration';
import { About } from '../About/About';
import { useTranslation } from 'react-i18next';

export const Modals: React.FC = (): JSX.Element => {

  const { t } = useTranslation();

  return ( 
    <div>
      <Modal  
        title={t('CONFIGURATION')} 
        minWidth={960}
        minHeight={800}
        name={MODAL.CONFIGURATION}
      >  
        <Configuration /> 
      </Modal>
      <Modal 
        title={t('ABOUT')} 
        minWidth={960}
        minHeight={800}
        name={MODAL.ABOUT}
      >
        <About />
      </Modal>
    </div> 
  );
};
 