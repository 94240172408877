import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { COOKIE_TOKEN } from '../constants/cookie';
import { deleteCookie, getCookie } from '../utils/cookie/cookie';
import { setLogOut } from '../store/actions/app/auth/authActions';
import { Store } from '../store';
export * from './airLoop/airLoop';
export * from './softReduction/softReduction';
export * from './commonLines/commonLines';
export * from './auth/auth';

export const api = 'https://hmi-back.azurewebsites.net/';

const apiService = axios.create({
  baseURL: api,
  withCredentials: true,
});

apiService.interceptors.request.use((config): AxiosRequestConfig => {
  const cookie = getCookie(COOKIE_TOKEN);
  config.headers.Authorization = `Bearer ${cookie}`;
  return config;
});

export const setupInterceptors = (store: Store): void => {

  apiService.interceptors.response.use((config): AxiosResponse => {
    return config;
  }, (error: AxiosError): void => {
    if (error.response && error.response.status === 401) {
      if (getCookie(COOKIE_TOKEN)) deleteCookie(COOKIE_TOKEN);
      store.dispatch(setLogOut());
    }
  });
};

export { apiService };