import { createSelector } from 'reselect';
import { RootReducer } from '../../';
import { LeftPanelState } from '../../types/leftPanel';


export const getLeftPanelData = (state: RootReducer): LeftPanelState => state.leftPanel;

export const getStatusLeftPanel = (state: RootReducer): boolean => state.leftPanel.isOpen;

export const getMode = createSelector( 
  getLeftPanelData,
  (data: LeftPanelState) => data.mode || ''
);

  
export const getHeatID = createSelector( 
  getLeftPanelData,
  (data: LeftPanelState) => data.heatID || 0
);

export const getCastLength = createSelector( 
  getLeftPanelData,
  (data: LeftPanelState) => data.castLength || 0
); 

export const getSteelGrade = createSelector( 
  getLeftPanelData,
  (data: LeftPanelState) => data.steelGrade || ''
);

export const getCastingTemperature = createSelector( 
  getLeftPanelData,
  (data: LeftPanelState) => data.castingTemperature || []
);

export const getCastDimensiton = createSelector( 
  getLeftPanelData,
  (data: LeftPanelState) => data.castDimensiton || []
);

export const getSolidificationLength = createSelector( 
  getLeftPanelData,
  (data: LeftPanelState) => data.solidificationLength || 0 
);

export const getSpeed = createSelector( 
  getLeftPanelData,
  (data: LeftPanelState) => data.speed || 0
); 

export const getSpraywater = createSelector( 
  getLeftPanelData,
  (data: LeftPanelState) => data.spraywater || 0
); 

export const getMoldWaterVolume = createSelector( 
  getLeftPanelData, 
  (data: LeftPanelState) => data.moldWaterVolume || []
); 

export const getMoldWaterTemperature = createSelector( 
  getLeftPanelData,
  (data: LeftPanelState) => data.moldWaterTemperature || []
); 

export const getLevel = createSelector( 
  getLeftPanelData,
  (data: LeftPanelState) => data.level || 0
); 