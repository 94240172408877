import React, { useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { defaultOptions } from './defaultOptions';
import { useSelector, useDispatch } from 'react-redux';
import { max } from 'lodash';
import { getWaterSpecificChart } from '../../../store/selectors/charts/waterSpecific/waterSpecificSelector';   
import { WaterSpecificMiniChart } from '../WaterSpecificMiniChart/WaterSpecificMiniChart';
import { defaultOptions as defaultOptionsMiniChart } from '../WaterSpecificMiniChart/defaultOptions';
import coolMarker from '../../../assets/images/cool_symbol.png';
import coolMarkerRotate from '../../../assets/images/cool_symbolRotate.png';
import { fetchWaterSpecificRequest } from '../../../store/actions/charts/waterSpecific/waterSpecificActions';
import { Spinner } from '../../common/Spinner/Spinner';
import { makeStyles } from '@material-ui/core';
import { getHeaderTopMenuBtnStatus } from '../../../store/selectors/header/headerSelector';

const useStyles = makeStyles({
  spinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  }
});

export const WaterSpecific = (): JSX.Element => {  

  const waterSpecific = useSelector(getWaterSpecificChart);
  const maxLengthX = useMemo(()=> max(waterSpecific.castingSpeedSetpoints), [waterSpecific.castingSpeedSetpoints]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const topMenuIsHide = useSelector(getHeaderTopMenuBtnStatus);

  useEffect(() => {
    if (waterSpecific.data === null) {
      dispatch(fetchWaterSpecificRequest());
    }
  }, [dispatch, waterSpecific.data]); 

  const coolzonesPointsSeriesTopMiniChart = useMemo(() => ({
    type: 'line',
    name: 'CoolzonePointsTop',
    dataLabels: {
      enabled: true,
    },
    lineWidth: 0,
    marker: {
      enabled: true,
      symbol: `url(${coolMarker})`, 
    },
    states: {
      hover: {
        enabled: false,
      }
    },
    data: waterSpecific.coolZones?.map((item) => ({
      x: item.value,
      y: 20,
      name: item.label,
      dataLabels: {
        format: item.label,
        style: {
          fontWeight: 400
        }
      },
    }))
  }), [waterSpecific.coolZones]);

  const coolzonesPointsSeriesBottomMiniChart = useMemo(() => ({
    type: 'line',
    name: 'CoolzonePointsBottom',
    dataLabels: {
      enabled: true,
    },
    lineWidth: 0,
    marker: {
      enabled: true,
      symbol: `url(${coolMarkerRotate})`,
    },
    states: {
      hover: {
        enabled: false,
      }
    },
    data: waterSpecific.coolZones?.map((item) => ({
      x: item.value,
      y: 80,
      name: item.label,
      dataLabels: {
        format: item.label,
        style: {
          fontWeight: 400
        }
      },
    }))
  }), [waterSpecific.coolZones]);

  const specificFixColumns = useMemo(() =>({
    name: 'Fix Columns',
    color: {
      linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
      stops: [
        [0, '#00256e'],
        [1, '#003bb0']
      ]
    },
    data: waterSpecific.data?.WaterSpecificFix?.map((item, index) => (
      {
        type: 'column',
        x: waterSpecific.castingSpeedSetpoints && waterSpecific.castingSpeedSetpoints[index],
        y: item,
        dataLabels: {
          backgroundColor: '#003bb0',
          color: '#fff',
        },
      }
    )), 
  }), [waterSpecific.data?.WaterSpecificFix, waterSpecific.castingSpeedSetpoints ]);

  const specificLooseColumns = useMemo(() =>({
    name: 'Loose Columns',
    color: {
      linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
      stops: [
        [0, '#00256e'],
        [1, '#003bb0']
      ]
    },
    data: waterSpecific.data?.WaterSpecificLoose?.map((item, index) => (
      {
        type: 'column',
        x: waterSpecific.castingSpeedSetpoints && waterSpecific.castingSpeedSetpoints[index],
        y: item,
        dataLabels: {
          backgroundColor: '#003bb0',
          color: '#fff',
        },
      }
    )),
  }), [waterSpecific.data?.WaterSpecificLoose, waterSpecific.castingSpeedSetpoints]);

  const optionsForTopMiniChart = useMemo(() => ({
    ...defaultOptionsMiniChart,
    chart: { 
      ...defaultOptionsMiniChart.chart,
      height: 90,
    },
    series: [coolzonesPointsSeriesTopMiniChart]
  }), [coolzonesPointsSeriesTopMiniChart]);

  const optionsForBottomMiniChart = useMemo(() => ({
    ...defaultOptionsMiniChart,
    
    title: {
      ...defaultOptionsMiniChart.title,
      text: null
    },
    series: [coolzonesPointsSeriesBottomMiniChart]
  }), [coolzonesPointsSeriesBottomMiniChart]);

  const bottomWaterSpecificOptions = useMemo(() => ({
    ...defaultOptions,
    title: {
      text: null
    },
    yAxis: defaultOptions.yAxis.map((item) => ({
      ...item,
      title: {
        ...item.title,
        text: 'Spec. Water (Loose Side)/ I/(m² min)'
      }
    })),
    xAxis: defaultOptions.xAxis.map((item) => ({
      ...item,
      max: maxLengthX && Math.floor(maxLengthX) + 1.2,
    })),
    
    series: [specificFixColumns]
  }), [specificFixColumns, maxLengthX]); 

  const topWaterSpecificOptions = useMemo(() => ({
    ...defaultOptions,
    title: {
      text: null
    },
    yAxis: defaultOptions.yAxis.map((item) => ({
      ...item,
      title: {
        ...item.title,
        text: 'Spec. Water (Fixed Side)/ I/(m² min)'
      }
    })),
    xAxis: defaultOptions.xAxis.map((item) => ({
      ...item,
      max: maxLengthX && Math.floor(maxLengthX) + 1.2,
    })),
    
    series: [specificLooseColumns] 
  }), [specificLooseColumns, maxLengthX]); 
  
  if (!waterSpecific.data) {
    return <div className={classes.spinnerContainer}><Spinner /></div>;
  }

  const styles = topMenuIsHide ? { height: 'calc( 50vh - 137px )', minHeight: '250px' } : { height: 'calc( 50vh - 217px )', minHeight: '250px' };

  return ( 
    <> 
      <WaterSpecificMiniChart otherOptions={optionsForTopMiniChart} />
      <HighchartsReact 
        key={topMenuIsHide ? 'closed_first' : 'opened_first'}
        containerProps={{ style: styles }} 
        highcharts={Highcharts}  
        options={topWaterSpecificOptions}
      /> 
      <HighchartsReact
        key={topMenuIsHide ? 'closed_second' : 'opened_second'}
        containerProps={{ style: styles }}  
        highcharts={Highcharts}  
        options={bottomWaterSpecificOptions}
      /> 
      <div style={{ paddingTop: 5 }}>
        <WaterSpecificMiniChart otherOptions={optionsForBottomMiniChart} />
      </div>
    </>
  );
};
