import { AxiosPromise } from 'axios';
import { apiService } from '../index';


export const softReduction = {
  getShellFS(): AxiosPromise<string> {
    return apiService.get('/Main/SoftReductionByFilter?filter=Length,ThicknessTopFl0_00,ThicknessTopFl0_20,ThicknessTopFl0_40,ThicknessTopFl0_60,ThicknessTopFl0_80,ThicknessTopFl1_00');
  },
  getRollGap(): AxiosPromise<string> {
    return apiService.get('/Main/SolidificationByFilter?filter=Length,RollGaps,SegmentGapCoorEnd,RollPasslineCoor');
  }
};  


 