
export type TypeRollGapData = {
  Length: number[] | null,
  RollGaps: number[] | null,
  SegmentGapCoorEnd: number[] | null,
  RollPasslineCoor: number[] | null,

}

export interface DataRollGapState {
  data: TypeRollGapData | null,
}

export enum RollGapActionsTypes {
  SET_ROLL_GAP_DATA = 'SET_ROLL_GAP_DATA',
  FETCH_ROLL_GAP_REQUEST = 'FETCH_ROLL_GAP_REQUEST'
}
 

type FetchRollGapRequest = {
  type: RollGapActionsTypes.FETCH_ROLL_GAP_REQUEST
};

type SetRollGapData = {
    type: RollGapActionsTypes.SET_ROLL_GAP_DATA,
    payload: TypeRollGapData
}

export type RollGapAction = 
FetchRollGapRequest |
SetRollGapData;