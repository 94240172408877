import { ILogin } from '../../../../interfaces/ILogin';
import { AuthAction, AuthActionTypes } from '../../../types/app/auth';

export const setLogIn = (): AuthAction => ({
  type: AuthActionTypes.LOG_IN,
});

export const setLogOut = (): AuthAction => ({
  type: AuthActionTypes.LOG_OUT
});

export const tryLogIn = (payload: ILogin): AuthAction => ({
  type: AuthActionTypes.LOG_TRY,
  payload,
});